import React from 'react';
import {styled} from '@mui/material/styles';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';

interface Props {
    variant: 'buffer' | 'determinate' | 'indeterminate' | 'query';
    value: number;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#EEEEEE'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#43A047'
    },
}));

const ProgressBar = (props: Props) => {
    return (
        <BorderLinearProgress variant={props.variant} value={props.value} />
    )
}

export default ProgressBar