export const passwordValidation = (password: string) => {
    const letterCheck = /[a-zA-Z]/.test(password)
    const numberCheck = /[0-9]/.test(password)
    const specialCharCheck = /[!@#$%^&*]/.test(password)

    if (password.length < 6) {
        return {
            strength: 'Poor',
            indicator: 20,
            color: '#f44336'
        }
    }

    if ((password.length >= 6 && password.length <= 8) && letterCheck && numberCheck) {
        return {
            strength: 'Weak',
            indicator: 40,
            color: '#f44336'
        }
    }

    if ((password.length >= 8 && password.length <= 10) && letterCheck && numberCheck && !specialCharCheck) {
        return {
            strength: 'Moderate',
            indicator: 60,
            color: '#ffeb3b'
        }
    }

    if ((password.length >= 8 && password.length <= 10) && letterCheck && numberCheck && specialCharCheck) {
        return {
            strength: 'Good',
            indicator: 80,
            color: '#4caf50'
        }
    }

    if ((password.length >= 10) && letterCheck && numberCheck && specialCharCheck) {
        return {
            strength: 'Strong',
            indicator: 100,
            color: '#4caf50'
        }
    }
}