export const actionIds = {
    NAVIGATE: 'NAVIGATE',
    ERROR: 'ERROR',
    ERROR_CLEAR: 'ERROR_CLEAR',
    ERROR_ACCESS_DENIED: 'ERROR_ACCESS_DENIED',
    RESET_STORE: 'RESET_STORE',
    ERROR_INTERNAL_SERVER_ERROR: 'ERROR_INTERNAL_SERVER_ERROR',
    LOADING_SET: 'LOADING_SET',
    LOADING_UNSET: 'LOADING_UNSET',
    GET_CONFIG: 'GET_CONFIG',
    SET_CONFIG: 'SET_CONFIG',
    SET_LOGIN_DATA: 'SET_LOGIN_DATA',
    UNSET_LOGIN_DATA: 'UNSET_LOGIN_DATA',
    SUBMIT_LOGIN: 'SUBMIT_LOGIN',
    SUBMIT_LOGOUT: 'SUBMIT_LOGOUT',
    SET_USER: 'SET_USER',
    GET_SYNC_QUEUE: 'GET_SYNC_QUEUE',
    SET_SYNC_QUEUE: 'SET_SYNC_QUEUE',
    SET_UNSYNCED_ITEMS: 'SET_UNSYNCED_ITEMS',
    SET_HARVEST_DATA: 'SET_HARVEST_DATA',
    RESET_HARVEST_DATA: 'RESET_HARVEST_DATA',
    SUBMIT_HARVEST: 'SUBMIT_HARVEST',
    GET_FARMS_LIST: 'GET_FARMS_LIST',
    GET_CROPS_LIST: 'GET_CROPS_LIST',
    SET_CROPS_LIST: 'SET_CROPS_LIST',
    GET_WAREHOUSE_LIST: 'GET_WAREHOUSE_LIST',
    SET_WAREHOUSE_LIST: 'SET_WAREHOUSE_LIST',
    GET_WAREHOUSE_DETAILS: 'GET_WAREHOUSE_DETAILS',
    SET_WAREHOUSE_DETAILS: 'SET_WAREHOUSE_DETAILS',
    GET_WAREHOUSE_TRANSACTIONS: 'GET_WAREHOUSE_TRANSACTIONS',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    UNSET_NOTIFICATION: 'UNSET_NOTIFICATION',
    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
    SET_WAREHOUSE_TRANSACTIONS: 'SET_WAREHOUSE_TRANSACTIONS',
    SET_CREATE_WAREHOUSE_TRANSACTIONS_DATA: 'SET_CREATE_WAREHOUSE_TRANSACTIONS_DATA',
    RESET_CREATE_WAREHOUSE_TRANSACTIONS_DATA: 'RESET_CREATE_WAREHOUSE_TRANSACTIONS_DATA',
    SUBMIT_TRANSACTION: 'SUBMIT_TRANSACTION',
    SET_FARMS_LIST: 'SET_FARMS_LIST',
    GET_FARMS_DETAILS: 'GET_FARMS_DETAILS',
    SET_FARMS_DETAILS: 'SET_FARMS_DETAILS',
    SET_SELECTED_FARM: 'SET_SELECTED_FARM',
    GET_ZONE_LIST: 'GET_ZONE_LIST',
    SET_ZONE_LIST: 'SET_ZONE_LIST',
    SET_SELECTED_ZONE: 'SET_SELECTED_ZONE',
    GET_ZONES_DETAILS: 'GET_ZONES_DETAILS',
    SET_ZONES_DETAILS: 'SET_ZONES_DETAILS',
    GET_LOT_DETAILS: 'GET_LOT_DETAILS',
    SET_LOT_DETAILS: 'SET_LOT_DETAILS',
    GET_REPORTS_ACTIVITY: 'GET_REPORTS_ACTIVITY',
    SET_REPORTS_ACTIVITY: 'SET_REPORTS_ACTIVITY',
    UNSET_REPORTS_ACTIVITY: 'UNSET_REPORTS_ACTIVITY',
    SET_REPORTS_IMAGE_URL: 'SET_REPORTS_IMAGE_URL',
    SET_REPORTS_GRAPH: 'SET_REPORTS_GRAPH',
    SET_REPORTS_QUERY: 'SET_REPORTS_QUERY',
    UNSET_REPORTS_QUERY: 'UNSET_REPORTS_QUERY',
    SET_ADMIN_DATA: 'SET_ADMIN_DATA',
    SET_GPS_POSITION: 'SET_GPS_POSITION',
    GET_ONLINE_STATUS: 'GET_ONLINE_STATUS',
    GET_USERS_LIST: 'GET_USERS_LIST',
    SET_USERS_LIST: 'SET_USERS_LIST'
};

export interface BaseAction<T = any> {
    type: string;
    payload: T;
}
