const routes = {
    HOME_PAGE: '/',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    SCAN_QR_PAGE: '/scan-qr',
    UPDATE_STATUS_PAGE: '/update-status',
    UPDATE_STATUS_PAGE_2: '/update-status/2',
    UPDATE_STATUS_PAGE_3: '/update-status/3',
    DEBUG: '/debug',
    DEBUG_ERROR_LOGS: '/debug/error-logs',
    DEBUG_SCANNED_DATA: '/debug/scanned-data',
    DEBUG_LOG_DATA_HISTORY: '/debug/log-data-history',
    PROFILE: '/profile',
    PROFILE_SYNC_QUEUE: '/profile/sync-queue',
    ADMIN_PAGE: '/administration',
    WAREHOUSE_LIST: '/administration/warehouses/list',
    WAREHOUSE_EDIT: '/administration/warehouses/edit/:id',
    WAREHOUSE_DETAILS: '/administration/warehouses/details/:id',
    WAREHOUSE_CREATE_TRANSACTION: '/administration/warehouses/create-transaction/:id',
    REPORT_LIST: '/administration/reports',
    REPORT_OVERVIEW: '/administration/reports/overview',
    REPORT_EXPORT: '/administration/reports/export',
    REPORT_ACTIVITY: '/administration/reports/activity',
    REPORT_ACTIVITY_DETAILS: '/administration/reports/activity/details',
    ADMIN_USERS: '/administration/users',
    ADMIN_USERS_ADD: '/administration/users/add',
    ADMIN_USERS_DETAILS: '/administration/users/details/:id',
    ADMIN_FARMS: '/administration/farms',
    ADMIN_FARMS_ZONES: '/administration/farms/zones',
    ADMIN_FARMS_ZONES_ADD: '/administration/farms/zones/add',
    ADMIN_FARMS_ZONES_DETAILS: '/administration/farms/zones/details',
    ADMIN_FARMS_ZONES_LOTS: '/administration/farms/zones/lots'
}

export default routes