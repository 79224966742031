import React, {useState} from 'react'
import {IconButton, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {Delete, LocalFlorist} from '@mui/icons-material';
import PageLayout from '../organisms/PageLayout/PageLayout'
import {PageHeader} from "../organisms/PageHeader/PageHeader";
import LayoutContainer from "../atoms/LayoutContainer/LayoutContainer";
import {useDispatch,useSelector} from 'react-redux';
import {IRootState} from '../../types/IRootState';
import {ISyncState} from '../../types/ISyncState';
import {removeHarvestData} from '../../config/db';
import getSyncQueue from '../../state/actions/getSyncQueue';
import ConfirmOverlay from '../atoms/ConfirmOverlay';

const ProfileSyncQueuePage = () => {
    const dispatch = useDispatch()
    const {syncQueue} = useSelector<IRootState>(state => state.sync) as ISyncState
    const [tempId, setTempId] = useState('');
    const [open, setOpen] = useState(false);

    const handleDeleteQueue = (id) => {
        setOpen(!open)
        setTempId(id)
    }

    const handleClose = (e) => {
        if (e.currentTarget.id === 'agree') {
            // remove data from indexedDB
            removeHarvestData(tempId)
            dispatch(getSyncQueue())
        }
        setOpen(!open)
        setTempId('')
    };

    return (
        <div>
            <PageLayout>
                <PageHeader caption="Profile / Sync Queue"/>
                <LayoutContainer>
                    {syncQueue.map((item, i) => {
                        return (
                            <ListItem key={i}>
                                <ListItemIcon>
                                    <LocalFlorist />
                                </ListItemIcon>
                                <ListItemText primary={`Queue: ${item.data.lot_id || 'unknown'}`} />
                                <IconButton edge="end" aria-label="delete" onClick={() => {handleDeleteQueue(item.id)}}>
                                    <Delete />
                                </IconButton>
                            </ListItem>
                        )
                    })}
                    {syncQueue.length === 0 && <ListItemText primary={'Everything is synchronised'} />}
                    <ConfirmOverlay 
                        open={open}
                        title={'Deleting Data'}
                        content={'Are you sure you want to proceed?'}
                        onClick={handleClose} 
                    />
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default ProfileSyncQueuePage
