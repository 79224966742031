import { useLayoutEffect, useState } from 'react';

const useKeyboard = () => {
    const [height, setHeight] = useState(0)
    const [initialHeight] = useState(document.documentElement.clientHeight) // without browser address bar
    const [keyboardShown, setKeyboardShown] = useState(false)
    const [keyboardHeight, setKeyboardHeight] = useState(0)

    useLayoutEffect(() => {
        setKeyboardShown(height < initialHeight)
        setKeyboardHeight(initialHeight - height)

        function updateSize() {
            setHeight(window.innerHeight)
        }
        window.addEventListener('resize', updateSize)
        updateSize()
        return () => window.removeEventListener('resize', updateSize)
    }, [height, initialHeight])

    return { keyboardShown, keyboardHeight }
}

export default useKeyboard