import {actionIds, BaseAction} from "../common";
import {ISyncState} from "../../types/ISyncState";

const defaultState: ISyncState = {
    syncQueue: [],
    syncLots: {
        lastStarted: new Date(),
        totalAmount: 0,
        lastOffset: 0,
        inProgress: false,
    },
    syncLotCrops: {
        lastStarted: new Date(),
        totalAmount: 0,
        lastOffset: 0,
        inProgress: false,
    },
    unsyncedItems: 0
}

const syncReducer = (state: ISyncState = defaultState, action: BaseAction): ISyncState => {
    switch (action.type) {
        case actionIds.SET_SYNC_QUEUE:
            return {
                ...state,
                syncQueue: action.payload.syncQueue
            }
        
        case actionIds.SET_UNSYNCED_ITEMS:
            return {
                ...state,
                unsyncedItems: action.payload.unsyncedItems
            }

            // TODO: create actions for updating syncLots / syncLotCrops

        default:
            return state
    }
}

export default syncReducer;
