import {actionIds, BaseAction} from "../common";
// import IAdminState from "../../types/IAdminState";

const setAdminDataAction: (data: any) =>
    BaseAction = (data) => ({
    type: actionIds.SET_ADMIN_DATA,
    payload: {data},
});

export default setAdminDataAction;
