import {actionIds, BaseAction} from "../common";
import INotification from "../../types/INotification";

const unsetNotificationAction: (data: INotification) =>
    BaseAction = (data) => ({
    type: actionIds.UNSET_NOTIFICATION,
    payload: {
        ...data,
    },
});

export default unsetNotificationAction;
