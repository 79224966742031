import {actionIds, BaseAction} from "../common";
import IUserState from "../../types/IUserState";

const setUsersListAction: (data: IUserState) =>
    BaseAction = (data) => ({
    type: actionIds.SET_USERS_LIST,
    payload: {
        ...data
    }
});

export default setUsersListAction;