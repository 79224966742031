import Dexie from 'dexie';
import {cyrb53} from "../lib/cyrb53";
import {getDuration} from '../lib/formatDate';
import {ISyncLotsData} from '../types/ILotCrop';

// Dexie Typescript setup
interface User {
    id?: number;
    data?: string
}

interface Harvest {
    id?: number;
    data?: string;
    is_skipped?: number;
    scanned_at?: number;
    raw_qr_data?: string;
}

interface ErrorLog {
    id?: number;
    url: string;
    data?: string;
    data_hash: string;
    error: string;
    created_at: number;
}

interface SyncLots {
    id: number;
    crop_status: string;
    name: string
    description: string;
    zone: string;
    row: string;
    column: number;
    status: string;
    status_id: number;
    planted_date: string;
    updated_date: string;
}

interface LotCropData {
    id?: number;
    lot_id: number;
    crop_id: number;
    index: number;
    status: string;
    updated: number;
}
interface LotDataHistory {
    id?: number;
    lot_id: number;
    crop_status_id: number;
    amount: number;
    weight: number;
    notes: string;
    scanned_at: number;
    image_hash: string;
}

interface ScannedQrHistory {
    id?: number;
    lot_id: number;
    raw_data: string;
    scanned_at: number;
}

// Declare Database
class Offline_db extends Dexie {
    public user: Dexie.Table<User, number>; // id is number in this case
    public harvest: Dexie.Table<Harvest, number>; // id is number in this case
    public error_log: Dexie.Table<ErrorLog, number>; // id is number in this case
    public sync_lots: Dexie.Table<SyncLots, number>; // id is number in this case
    public lot_crop_data: Dexie.Table<LotCropData, number>; // id is number in this case
    public log_data_history: Dexie.Table<LotDataHistory, number>; // id is number in this case
    public scanned_qr_history: Dexie.Table<ScannedQrHistory, number>; // id is number in this case

    public constructor() {
        super("Offline_db");
        this.version(1).stores({
            user: "id, data",
            harvest: "++id, data"
        });
        this.version(2).stores({
            user: "id, data",
            harvest: "++id, data, is_skipped, scanned_at, raw_qr_data"
        }).upgrade(trans => {
            return trans.table('harvest').toCollection().modify((harvest: Harvest) => {
                harvest.is_skipped = 0
                harvest.scanned_at = Date.now()
            })
        });
        this.version(3).stores({
            user: "id, data",
            harvest: "++id, data, is_skipped, scanned_at, raw_qr_data",
            error_log: "++id, number, url, data, data_hash, error, created_at",
            sync_lots: "++id, crop_status, name, description, zone, row, column, status, status_id, planted_date, updated_date",
            lot_crop_data: "++id, lot_id, crop_id, index, status, updated",
            log_data_history: "++id, lot_id, crop_status_id, amount, weight, notes, created_at",
            scanned_qr_history: "++id, lot_id, raw_data, scanned_at"
        })
        this.user = this.table("user");
        this.harvest = this.table("harvest");
        this.error_log = this.table("error_log");
        this.sync_lots = this.table("sync_lots");
        this.lot_crop_data = this.table("lot_crop_data");
        this.log_data_history = this.table("log_data_history");
        this.scanned_qr_history = this.table("scanned_qr_history");
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////                                      I N I T I A L                                             //////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

const db = new Offline_db();

db.open().then(() => {
    db.tables.forEach((table) => {
        console.log("DB Table >>> " + table.name);
    });
}).catch((err) => {
    console.log(err.stack || err)
})

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////                                         U S E R                                                //////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const listUserData = async () => {
    let results = await db.user.get(1)

    if (results) {
        return results.data
    }
}

export const saveUserData = (data) => {
    db.user.toArray().then((table) => {
        if (table.length === 0) {
            let newData = {
                id: 1,
                data: data
            }

            db.user.add(newData)
        } else {
            let newData = {
                id: 1,
                data: data
            }

            db.user.update(1, newData)
        }
    })
}

export const removeUserData = () => {
    let newData = {
        id: 1,
        data: ''
    }
    db.user.update(1, newData)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////                                    H A R V E S T  D A T A                                      //////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getNextUnsyncedHarvestData = async () => {
    // Count the table row
    return db.harvest.count().then(async (rows) => {
        if (rows > 0) {
            // FIFO
            let harvestData = await db.harvest.where({is_skipped: 0}).first().then((result) => {
                console.log('found item', result)
                return result
            })
            return harvestData
        }
    })
}

export const listHarvestDataArray = async () => {
    return db.harvest.toArray().then(async (table) => {
        return table
    })
}

export const getUnsyncedHarvestDataCount = async () => {
    return db.harvest.count()
}

export const saveHarvestData = (data) => {
    return db.harvest.toArray().then(async (table) => {
        db.harvest.add({data, is_skipped: 0, scanned_at: Date.now()})
    })
}

export const skipHarvestData = (id) => {
    db.harvest.where('id').equals(id).first().then(result => {
        if (result) {
            result.is_skipped = 1
            db.harvest.update(id, result)
        }
    });
}

export const removeHarvestData = (id) => {
    db.harvest.where('id').equals(id).delete();
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////                                      E R R O R   L O G                                         //////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const listErrorLogs = async () => {
    return db.error_log.toArray()
}

export const saveErrorLog = async (url: string, error: string, data?: any) => {
    let dataStr = JSON.stringify(data) ?? ''
    const dataHash = cyrb53(dataStr)

    const dataHashFound = await db.error_log.where('data_hash').equals(dataHash).count() > 0
    if (dataHashFound) {
        dataStr = ''
    }

    await db.error_log.add({
        created_at: Date.now(),
        url,
        error,
        data: dataStr,
        data_hash: dataHash
    })
}

// Delete all entries older than 30 days
export const cleanupErrorLogs = async () => {
    db.error_log.filter(obj => getDuration(obj.created_at).days >= 30).delete()
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////                                   S Y N C  L O T S  D A T A                                    //////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const listSyncLots = async () => {
    return db.sync_lots.toArray().then(async (table) => {
        return table
    })
}

export const getSyncLotsById = async (id) => {
    return db.sync_lots.get(Number(id))
}

export const saveSyncLots = async (lots) => {
    await db.sync_lots.bulkPut(lots)
}

// replaced with bulkPut
export const updateSyncLotsById = async (lots) => {
    lots.forEach((lot: SyncLots) => {
        db.sync_lots.update(lot.id, lot)
    })
}

export const removeSyncLots = () => {
    db.sync_lots.clear()
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////                                L O G   D A T A   H I S T O R Y                                 //////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const listLogDataHistory = async () => {
    return db.log_data_history.toArray()
}

export const saveLogDataHistory = async (lot_id: number, crop_status_id: number, amount: number, weight: number, notes: string, image_data: string) => {
    await db.log_data_history.add({
        amount,
        scanned_at: Date.now(),
        crop_status_id,
        lot_id,
        notes,
        weight,
        image_hash: cyrb53(image_data)
    })
}

// Delete all entries older than 30 days
export const cleanupLogDataHistory = async () => {
    db.log_data_history.filter(obj => getDuration(obj.scanned_at).days >= 30).delete()
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////                           L O G   S C A N N E D   Q R   H I S T O R Y                          //////
////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const listScannedQrHistory = async () => {
    return db.scanned_qr_history.toArray()
}

export const saveScannedQrHistory = async (lot_id: number, raw_data: string) => {
    await db.scanned_qr_history.add({
        lot_id,
        raw_data,
        scanned_at: Date.now(),
    })
}

// Delete all entries older than 30 days
export const cleanupScannedQrHistory = async () => {
    db.scanned_qr_history.filter(obj => getDuration(obj.scanned_at).days >= 30).delete()
}