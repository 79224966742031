import React, {ReactNode} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
    className?: string;
    open: boolean;
    title?: string;
    content?: string | ReactNode;
    onClick?: (e: React.MouseEvent) => void;
}
const ConfirmOverlay = (props: Props) => {
    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.onClick}
            >
                <DialogTitle>{props.title || 'Title Here'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.content || 'Content Here'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="agree" onClick={props.onClick} color="primary">
                        Agree
                    </Button>
                    <Button id="cancel" onClick={props.onClick} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ConfirmOverlay