import {actionIds, BaseAction} from "../common";
import IHarvestState from "../../types/IHarvestState";

const defaultState: IHarvestState = {
    lot_id: '',
    weight: '',
    amount: '',
    notes: '',
    imageData: '',
    // version 0.1
    lot: {
        id: '',
        name: '',
        zone: '',
        row: '',
        column: '',
        status: '',
        updated_date: ''
    },
    //
    crop_id: '',
    status_id: '',
    // version 2
    version: '',
    zoneName: '',
    zoneId: '',
    cropsPerLot: '',
    lot_crop_index: '' 
    //         
}

const harvestReducer = (state: IHarvestState = defaultState, action: BaseAction): IHarvestState => {
    switch (action.type) {
        case actionIds.SET_HARVEST_DATA:
            return {
                ...state,
                ...action.payload.data,
            }

        // TODO: add GET_LOT_DETAILS call in sagas to retrieve the data from indexed db and calls SET_LOT_DETAILS once the data has been fetched,
        //  GET_LOT_DETAILS should be called from UpdateStatus1 component

        // Does not seem to be used anymore?
        // TODO: call once data has been fetched from indexed db and replace code with actual data from indeed db
        case actionIds.SET_LOT_DETAILS:
            return {
                ...state,
                lot: {
                    ...action.payload,
                }
            }

        case actionIds.RESET_HARVEST_DATA:
            return {
                ...state,
                lot_id: '',
                weight: '',
                amount: '',
                notes: '',
                imageData: ''
            }

        default:
            return state
    }
}

export default harvestReducer;
