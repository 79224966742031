import React, { useState} from 'react';
import {Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Switch, TextField} from "@mui/material";
import {Check, Close, Create, Delete, LocalHospital} from '@mui/icons-material';
import { Alert } from '@mui/material';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import {PageHeader} from "./PageHeader/PageHeader";
import PageLayout from "./PageLayout/PageLayout";
import SearchBar from '../atoms/SearchBar';
// import { TabbarLayout, Panel } from './TabbarLayout/TabbarLayout';
import ButtonCategories from './TabbarLayout/ButtonCategories';
import FormInput from '../atoms/FormInput';
import {IRootState} from "../../types/IRootState";
import IUserState from "../../types/IUserState";
import {IUsers} from "../../types/IUserState";
import {formatString} from '../../lib/formatString';

interface UrlParams {
    id?: string | undefined;
}

const languages = [
    'English',
    'Language 2',
    'Language 3'
];

const mockFarms = [
    {
        data1: 'Farm 1',
        data2: '4 Zones',
        data3: '3200'
    },
    {
        data1: 'Farm 2',
        data2: '1 Zone',
        data3: '3200'
    },
    {
        data1: 'Farm 3',
        data2: '12 Zones',
        data3: '3200'
    }
]

const mockWarehouses = [
    {
        data1: 'Warehouse 1',
        data2: '2 Crops'
    },
    {
        data1: 'Warehouse 1',
        data2: '1 Crop'
    }
]

const buttonCategories = [
    {
        name: 'Overview',
    },
    {
        name: 'Farms',
    },
    {
        name: 'Logs',
    },
    {
        name: 'Warehouses',
    }
]

const UsersDetails = () => {
    let { id } = useParams() as UrlParams
    const {users} = useSelector<IRootState>(state => state.user) as IUserState
    let user = users?.find((user: IUsers) => user.id === Number(id)) as IUsers

    const [language, setLanguage] = useState('English');
    const [farm, setFarm] = useState('Farm 1');
    const [collapse, setCollapse] = useState(user?.is_disabled);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedButton, setSelectedButton] = useState('Overview')

    const [editMode, setEditMode] = useState({
        name: false,
        email: false
    })
    const [editUser, setEditUser] = useState(user)

    const handleChangeLanguage = (language) => {
        setLanguage(language);
    };

    const handleChangeAddFarm = (farm) => {
        setFarm(farm);
    };

    const handleChangeDisableUSer = () => {
        setCollapse(!collapse)
    }

    const handleUserDetails = () => {

    }

    const handleCloseDialog = (event: any, reason: any) => {
        if (reason === 'backdropClick') {
            setDialogOpen(false)
        }
    }

    const handleChangeButton = (buttonCategoryName: string) => {
        setSelectedButton(buttonCategoryName)
    }

    const handleChangeEdit = (id: string, value: string | number) => {
        setEditUser({
            ...editUser,
            [id]: value
        })
    }

    const handleChangeEditMode = (key: string, value: boolean) => {
        setEditMode({
            ...editMode,
            [key]: value
        })
    }

    return (
        <PageLayout>
            <PageHeader caption="Management > Users" />
            <LayoutContainer className="layout-container-pad0">

                {/* Button Categories */}
                <ButtonCategories
                    data={buttonCategories} 
                    selectedButton={selectedButton} 
                    onClick={(buttonCategory) => handleChangeButton(buttonCategory.name)} 
                />

                {selectedButton === 'Overview' && <>
                    <div>
                        <div>&nbsp;</div>
                        <Collapse in={collapse}>
                            <Alert severity="warning">User is disabled</Alert>
                        </Collapse>
                    </div>

                    {Object.entries(user).filter((entry) => !['id', 'is_disabled'].includes(entry[0])).map(([key, val]) => {
                        return (
                            <Grid container key={key}>
                                {/* {key !== '' && key !== '' && <> */}
                                <Grid item xs={3}>
                                    <ListItem>
                                        <ListItemText primary={formatString(key) + ':'} />
                                    </ListItem>
                                </Grid>
                                <Grid item xs={7}>
                                    {!editMode[key] ?
                                        <ListItem>
                                            <ListItemText primary={editUser[key]} />
                                        </ListItem> :
                                        <ListItem>
                                            <TextField
                                                id="id"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                value={editUser[key]}
                                                onChange={evt => handleChangeEdit(key, evt.target.value)}
                                            />
                                        </ListItem>}
                                </Grid>
                                <Grid item xs>
                                    <div style={{ textAlign: 'right' }}>
                                        {/* Edit false*/}
                                        {!editMode[key] &&
                                            <ListItemIcon>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="edit"
                                                    style={{ backgroundColor: 'transparent' }}
                                                    onClick={() => { handleChangeEditMode(key, true) }}
                                                >
                                                    <Create />
                                                </IconButton>
                                            </ListItemIcon>}

                                        {/* Edit true*/}
                                        {editMode[key] &&
                                            <ListItemIcon>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="edit"
                                                    style={{ backgroundColor: 'transparent' }}
                                                    onClick={() => { handleChangeEditMode(key, false) }}
                                                >
                                                    <Check />
                                                </IconButton>
                                            </ListItemIcon>}
                                    </div>
                                </Grid>
                                {/* </>} */}
                            </Grid>
                        )
                    })}

                    {/* Phone */}
                    <Grid container>
                        <Grid item xs={3}>
                            <ListItem>
                                <ListItemText primary="Phone:" />
                            </ListItem>
                        </Grid>
                        <Grid item>
                            <ListItem>
                                <ListItemText primary="+6013000000" />
                            </ListItem>

                        </Grid>
                    </Grid>

                    {/* Password */}
                    <Grid container>
                        <Grid item xs={3}>
                            <ListItem>
                                <ListItemText primary="Password:" />
                            </ListItem>
                        </Grid>
                        <Grid item>
                            <ListItem component="a" href="#">
                                <ListItemText primary="Send new password" />
                            </ListItem>
                        </Grid>
                    </Grid>

                    {/* Language */}
                    <Grid container>
                        <Grid item xs={3}>
                            <ListItem>
                                <ListItemText primary="Language:" />
                            </ListItem>
                        </Grid>
                        <Grid item xs={9}>
                            <ListItem>
                                <TextField
                                    id="language"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    select
                                    value={language}
                                    onChange={evt => handleChangeLanguage(evt.target.value)}
                                    SelectProps={{
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            // getContentAnchorEl: null
                                        }
                                    }}
                                >
                                    {languages.map((language, i) => (
                                        <MenuItem key={i} value={language}>
                                            {language}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </ListItem>
                        </Grid>
                    </Grid>

                    {/* Disabled */}
                    <Grid container>
                        <Grid item xs={3}>
                            <ListItem>
                                <ListItemText primary="Disabled:" />
                            </ListItem>
                        </Grid>
                        <Grid item>
                            <ListItem>
                                <FormControlLabel
                                    control={<Switch checked={collapse} onChange={handleChangeDisableUSer} />}
                                    label="Disable User"
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                </>}

                {selectedButton === 'Farms' && <>
                    {/* Farms */}
                    {mockFarms.map((item, index) => {
                        return (
                            <List disablePadding key={index}>
                                <ListItem component="div">
                                    <ListItem disableGutters component="div">
                                        <ListItemText primary={item.data1} secondary={item.data2} />
                                    </ListItem>
                                    <ListItem disableGutters component="div">
                                        <ListItemText primary={`${item.data3} Lots`} style={{ textAlign: 'right' }} />
                                    </ListItem>
                                    <ListItemSecondaryAction onClick={() => { }}>
                                        <IconButton edge="end" aria-label="delete" >
                                            <Delete style={{ color: '#CF2A27' }} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        )
                    })}

                    {/* Add Farm */}
                    <Box display="flex" justifyContent="flex-end">
                        <Button size="small" variant="contained" style={{ width: 150 }} onClick={() => { setDialogOpen(true) }}>
                            Add Farm
                        </Button>
                    </Box>
                </>}

                {selectedButton === 'Logs' && <>
                    <Grid container>
                        <Grid item xs={4}>
                            <ListItemText primary="Search:" />
                        </Grid>
                        <Grid item xs={8}>
                            <SearchBar onChange={(data) => { }} />
                        </Grid>
                    </Grid>

                    <div style={{ paddingBottom: 8 }} />

                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={6}>
                            <FormInput
                                id="startDate"
                                label="From:"
                                type="date"
                                size={12}
                            // value={startDate.current}
                            // onChange={handleChangeDate}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <FormInput
                                id="endDate"
                                label="To:"
                                type="date"
                                size={12}
                            // value={endDate.current}
                            // onChange={handleChangeDate}
                            />
                        </Grid>
                    </Grid>

                    <div style={{ paddingBottom: 8 }} />

                    <ListItem style={{ backgroundColor: '#6aa84f' }}>
                        <ListItemText primary={'Logs'} style={{ color: '#FFFFFF' }} />
                    </ListItem>
                    TBD
                </>}

                {selectedButton === 'Warehouses' && <>
                    {/* Warehouses */}
                    {mockWarehouses.map((item, index) => {
                        return (
                            <List disablePadding key={index}>
                                <ListItem component="div">
                                    <ListItemText primary={item.data1} secondary={item.data2} />
                                </ListItem>
                                <ListItemSecondaryAction onClick={() => { handleUserDetails() }}>
                                    <IconButton edge="end" aria-label="delete" >
                                        <Delete style={{ color: '#CF2A27' }} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </List>
                        )
                    })}

                    {/* Add Warehouse */}
                    <Box display="flex" justifyContent="flex-end">
                        <Button size="small" variant="contained" style={{ width: 150 }} onClick={() => { }}>
                            Add Warehouse
                        </Button>
                    </Box>
                </>}

                {/* TODO: make it reusable */}
                <Dialog
                    open={dialogOpen}
                    fullWidth={true}
                    maxWidth={'xs'}
                    BackdropProps={{ style: { backgroundColor: "transparent" } }}
                    onClose={handleCloseDialog}
                >
                    {/* <DialogTitle>
                        <List disablePadding>
                            <ListItem disableGutters>
                                <ListItemIcon>
                                    <LocalHospital />
                                </ListItemIcon>
                                <ListItemText primary="Add Farm" style={{ position: 'absolute', left: 30 }} />
                                <ListItemSecondaryAction onClick={() => { }}>
                                    <IconButton edge="end" aria-label="close" >
                                        <Close />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    </DialogTitle> */}

                    <DialogTitle >
                        <Grid container>
                            <Grid item xs={6}>
                                <ListItem disableGutters>
                                    <ListItemIcon>
                                        <LocalHospital />
                                    </ListItemIcon>
                                    <ListItemText primary="Add Farm" style={{ position: 'absolute', left: 30 }} />
                                </ListItem>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ textAlign: 'right' }}>
                                    <IconButton edge="end" aria-label="edit" style={{ backgroundColor: 'transparent' }} >
                                        <Close />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogTitle>

                    <DialogContent>
                        <ListItem disableGutters>
                            <ListItemText primary={'Select the farm you wish to give this user access to:'} />
                        </ListItem>
                        <ListItem disableGutters>
                            <TextField
                                id="farm"
                                variant="outlined"
                                size="small"
                                fullWidth
                                select
                                value={farm}
                                onChange={evt => handleChangeAddFarm(evt.target.value)}
                                SelectProps={{
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        // getContentAnchorEl: null
                                    }
                                }}
                            >
                                {mockFarms.map((farm, i) => (
                                    <MenuItem key={i} value={farm.data1}>
                                        {farm.data1}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </ListItem>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => { }}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </LayoutContainer>
        </PageLayout>
    )
}

export default UsersDetails