import {all, call, delay, ForkEffect, put, select, spawn, takeEvery, takeLatest} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import {actionIds, BaseAction} from "../common";
import loadingUnsetAction from "../actions/loadingUnset";
import setFarmsList from "../actions/setFarmsList";
import setReportsActivityAction from '../actions/setReportsActivity';
// import {IWebSocket} from "../../api/sockets";
import {IConfig} from "../../types/IConfig";
import {
    createWarehouseTransaction,
    getCrops,
    getFarmsList,
    getFarmsDetails,
    getOnlineStatus,
    getReportsActivity,
    getReportsImageUrl,
    getReportsGraph,
    getUsersList,
    getWarehouseDetails,
    getWarehouseList,
    getWarehouseTransactions,
    getZonesDetails,
    getZonesList,
    harvest,
    login,
    logout,
    getSyncLots
} from "../../api/axios/api";
import {
    cleanupErrorLogs,
    cleanupLogDataHistory,
    cleanupScannedQrHistory,
    getNextUnsyncedHarvestData,
    getUnsyncedHarvestDataCount,
    listHarvestDataArray,
    removeHarvestData,
    removeUserData,
    saveUserData,
    skipHarvestData,
    saveSyncLots,
    getSyncLotsById
} from "../../config/db"
import {getDuration} from '../../lib/formatDate';
import {ISyncState} from '../../types/ISyncState';
import setFarmsDetailsAction from '../actions/setFarmsDetails';
import setWarehouseListAction from "../actions/setWarehouseList";
import setZonesListAction from '../actions/setZonesList';
import setZonesDetailsAction from '../actions/setZonesDetails';
import setCropsListAction from "../actions/setCropsList";
import getCropsListAction from "../actions/getCropsList";
import getSyncQueue from '../actions/getSyncQueue';
import setSyncQueue from '../actions/setSyncQueue';
import setUnsyncedItems from '../actions/setUnsyncedItems';
import submitHarvestAction from '../actions/submitHarvest';
import getOnlineStatusAction from '../actions/getOnlineStatus';
import setWarehouseDetailsAction from "../actions/setWarehouseDetails";
import setWarehouseTransactionsAction from '../actions/setWarehouseTransactions';
import setUsersListAction from '../actions/setUsersList';
import setNotificationAction from "../actions/setNotification";
import setUserAction from '../actions/setUser';
import unsetLoginDataAction from '../actions/unsetLoginData';
import resetStoreAction from '../actions/resetStore';
import setReportsImageUrlAction from '../actions/setReportsImageUrl';
import setReportsGraphAction from '../actions/setReportsGraph';
import setLotDetailsAction from '../actions/setLotDetails';

type TSaga = (params?: any) => Generator<ForkEffect<never>, void, unknown>

interface IRootSagaParams {
    history: any,
    // socket: IWebSocket,
}

let apiUrl: string = ''
const rootSaga = function* root(params: IRootSagaParams) {
    const sagas: TSaga[] = [
        watchGetConfigRequest,
        watchSetConfigRequest,
        watchOnNavigateAction,
        watchSignInRequest,
        watchSignOutRequest,
        watchGetUsersListRequest,
        watchGetSyncQueue,
        watchGetWarehouseListRequest,
        watchGetWarehouseDetailsRequest,
        watchSubmitHarvestRequest,
        watchGetFarmsListRequest,
        watchGetFarmsDetailsRequest,
        watchGetZonesListRequest,
        watchGetZonesDetailsRequest,
        watchGetReportsActivityRequest,
        watchGetCropsListRequest,
        watchGetOnlineStatusRequest,
        watchGetOnlineStatusRequest2,
        watchGetWarehouseTransactionRequest,
        watchSubmitWarehouseTransactionRequest,
        watchGetSyncLotsRequest,
        watchGetLotDetailsDBRequest
    ];

    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga, params);
                    break
                } catch (e) {
                    console.error(e)
                }
            }
        }))
    )
}

function* watchOnNavigateAction(params) {
    yield takeEvery(actionIds.NAVIGATE, function* (action: BaseAction) {
        yield put(loadingUnsetAction())
        params.history.push(action.payload.url)
    })
}

function* watchGetConfigRequest(params) {
    yield takeLatest(actionIds.GET_CONFIG, function* (action: BaseAction) {
        // yield params.socket.safeSend(JSON.stringify(action))
    })
}

function* watchSetConfigRequest(params) {
    yield takeLatest(actionIds.SET_CONFIG, function* (action: BaseAction<IConfig>) {
        apiUrl = action.payload.apiUrl
        yield apiUrl
    })
}

function* watchSignInRequest(params) {
    yield takeEvery(actionIds.SUBMIT_LOGIN, function* (action: BaseAction) {
        const {data} = yield call(login, action.payload);
        yield put(loadingUnsetAction())
        if (!data.success) {
            yield put(setNotificationAction({
                created: new Date(),
                type: 'error',
                message: data.error
            }))
        }
        yield put(unsetLoginDataAction())
        yield put(setUserAction(data))
        yield put(getCropsListAction());
        // Save user data to indexDB
        saveUserData(data);
    })
}

// function* watchSignInSuccess(params) {
//     yield takeEvery(actionIds.SIGN_IN_SUCCESS, function* (action: BaseAction) {
//         // load permission data on login
//     })
// }

function* watchGetCropsListRequest(params) {
    yield takeEvery(actionIds.GET_CROPS_LIST, function* (action: BaseAction) {
        const {data} = yield call(getCrops);
        yield put(setCropsListAction(data))
    })
}

function* watchGetWarehouseListRequest(params) {
    yield takeEvery(actionIds.GET_WAREHOUSE_LIST, function* (action: BaseAction) {
        const {data} = yield call(getWarehouseList);
        yield put(setWarehouseListAction(data))
    })
}

function* watchGetWarehouseDetailsRequest(params) {
    yield takeEvery(actionIds.GET_WAREHOUSE_DETAILS, function* (action: BaseAction) {
        const {data} = yield call(getWarehouseDetails, action.payload.id);
        yield put(setWarehouseDetailsAction(data))
    })
}

function* watchGetWarehouseTransactionRequest(params) {
    yield takeEvery(actionIds.GET_WAREHOUSE_TRANSACTIONS, function* (action: BaseAction) {
        const {data} = yield call(getWarehouseTransactions, action.payload.id);
        yield put(setWarehouseTransactionsAction(data))
    })
}

function* watchSubmitWarehouseTransactionRequest(params) {
    yield takeEvery(actionIds.SUBMIT_TRANSACTION, function* (action: BaseAction) {
        const {data} = yield call(createWarehouseTransaction, action.payload);
        if (!data.success) {
            yield put(setNotificationAction({
                type: 'error',
                message: data.error
            }))
        }
    })
}

function* watchGetUsersListRequest(params) {
    yield takeEvery(actionIds.GET_USERS_LIST, function* (action: BaseAction) {
        const {data} = yield call(getUsersList);
        yield put(setUsersListAction(data))
    })
}

function* watchSignOutRequest(params) {
    yield takeEvery(actionIds.SUBMIT_LOGOUT, function* (action: BaseAction) {
        const {data} = yield call(logout);
        if (data) {
            yield put(resetStoreAction())
            // Remove user data from indexDB
            removeUserData()
        }
    })
}

function* watchGetSyncQueue(params) {
    yield takeLatest(actionIds.GET_SYNC_QUEUE, function* (action: BaseAction) {
        const data = yield call(listHarvestDataArray);
        const dataCount = yield call(getUnsyncedHarvestDataCount);

        if (dataCount > 0) {
            yield put(submitHarvestAction())
        }

        yield put(setSyncQueue(data))
        yield put(setUnsyncedItems(dataCount))

        // Clean up data from indexDB
        yield call(cleanupErrorLogs)
        yield call(cleanupLogDataHistory)
        yield call(cleanupScannedQrHistory)
    })
}

function* watchSubmitHarvestRequest(params) {
    yield takeLatest(actionIds.SUBMIT_HARVEST, function* (action: BaseAction) {

        // List HarvestData from indexDB
        const harvestData = yield call(getNextUnsyncedHarvestData);

        // Expected output:
        // data: {lot_id: 1090, lot_crop_id: 3, status_id: 4, weight: 0, notes: '', …}
        // id: 34
        if (harvestData?.data) {
            if (typeof harvestData.data.lot_id === 'string') {
                harvestData.data.lot_id = parseInt(harvestData.data.lot_id)
            }
            const {data, status} = yield call(harvest, harvestData.data);
            if (data !== undefined && data.success === true) {
                // Remove harvest data from indexDB
                removeHarvestData(harvestData.id)

                // Call getSyncQueue()
                yield put(getSyncQueue())
            } else {
                // error happened, skip item if error found
                if (status === 400 && data && data.success === false) {
                    skipHarvestData(harvestData.id)
                    const dataCount = yield call(getUnsyncedHarvestDataCount);
                    yield put(setUnsyncedItems(dataCount))
                }
                if (status === 'Network Error') {
                    yield put(getOnlineStatusAction())
                }
                // yield put(submitHarvestAction())
            }
        }
    })
}

function* watchGetOnlineStatusRequest(params) {
    yield takeLatest(actionIds.GET_ONLINE_STATUS, function* (action: BaseAction) {
        const {online} = yield call(getOnlineStatus);
        if (online) {
            yield put(getSyncQueue())
        } else {
            yield delay(30000)
            yield put(getOnlineStatusAction())
        }
    })
}

function* watchGetOnlineStatusRequest2(params) {
    const networkChannel = eventChannel((emitter) => {    
        const events = ['offline', 'online']
        events.forEach((event => window.addEventListener(event, emitter)))
        return () => events.forEach((event) => window.removeEventListener(event, emitter))
    })
    
    yield takeEvery(networkChannel, function* (action: BaseAction) {
        if (action.type === 'offline') {
            // ...
        } else if (action.type === 'online') {
            // make request to hostUrl
            const {online} = yield call(getOnlineStatus);
            if (online) {
                // ...
            }
        }
    })
}

function* watchGetFarmsListRequest(params) {
    yield takeLatest(actionIds.GET_FARMS_LIST, function* (action: BaseAction) {
        const {data} = yield call(getFarmsList);
        yield put(setFarmsList(data))
    })
}

function* watchGetFarmsDetailsRequest(params) {
    yield takeLatest(actionIds.GET_FARMS_DETAILS, function* (action: BaseAction) {
        const {data} = yield call(getFarmsDetails, action.payload.farmId);
        yield put(setFarmsDetailsAction(data))
    })
}

function* watchGetZonesListRequest(params) {
    yield takeLatest(actionIds.GET_ZONE_LIST, function* (action: BaseAction) {
        const {data} = yield call(getZonesList);
        yield put(setZonesListAction(data))
    })
}

function* watchGetZonesDetailsRequest(params) {
    yield takeLatest(actionIds.GET_ZONES_DETAILS, function* (action: BaseAction) {
        const {data} = yield call(getZonesDetails, action.payload.zoneId);
        yield put(loadingUnsetAction())
        yield put(setZonesDetailsAction(data))
    })
}

function* watchGetReportsActivityRequest(params) {
    yield takeLatest(actionIds.GET_REPORTS_ACTIVITY, function* (action: BaseAction) {
        const [reports, imageUrl, graph] = yield all([
            call(getReportsActivity, action.payload),
            call(getReportsImageUrl, action.payload),
            call(getReportsGraph, action.payload)
        ])
        yield put(setReportsActivityAction(reports.data))
        yield put(setReportsImageUrlAction(imageUrl.data))
        yield put(setReportsGraphAction(graph.data))
    })
}

function* watchGetSyncLotsRequest() {
    // List SyncLots from redux state
    // TODO: check syncLots,
    // yield select returns a default state when the page refreshes, subsequent requests return the actual syncLots data
    // this does not occur with useSelector hook in the UI components
    const {syncLots}: ISyncState = yield select(state => state.sync)
    const {online} = yield call(getOnlineStatus);

    let offset = 0
    let has_more = true
    let duration = getDuration(syncLots.lastStarted).minutes
    // TODO: sync v2
    // the last run should be at least 5 minutes
    if (online) {
        while (has_more) {
            const {data} = yield call(getSyncLots, Date.now(), offset);
            offset = data.next_offset
            has_more = data.has_more
            saveSyncLots(data.data)

            // yield put({
            //     type: 'TEST_SET_SYNCLOTS',
            //     payload: {
            //         lastStarted: data.lastStarted,
            //         totalAmount: data.total,
            //         lastOffset: data.next_offset,
            //     }
            // })
        }
    }
    // recursive function call with a delay of x seconds
    yield delay(60000)
    yield call(watchGetSyncLotsRequest)
}

function* watchGetLotDetailsDBRequest() {
    yield takeLatest(actionIds.GET_LOT_DETAILS, function* (action: BaseAction) {
        const lotDetails = yield call(getSyncLotsById, action.payload.lot_id);
        yield put(setLotDetailsAction(lotDetails))
    })
}

export default rootSaga;