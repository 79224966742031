import React, {useEffect} from 'react';
import {List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {Book, Groups, Home, Store} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import getFarmsListAction from "../../state/actions/getFarmsList";
import getZonesListAction from '../../state/actions/getZonesList';
import getUsersListAction from '../../state/actions/getUsersList';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import PageLayout from '../organisms/PageLayout/PageLayout';
import {PageHeader} from "../organisms/PageHeader/PageHeader";
import routes from '../../config/routes';

const AdminPage = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFarmsListAction())
        dispatch(getZonesListAction())
        dispatch(getUsersListAction())
    }, [dispatch])

    return (
        <div className="">
            <PageLayout>
                <PageHeader caption="Management"/>
                <LayoutContainer>
                    <List>
                        <ListItemButton onClick={() => navigate(routes.ADMIN_USERS)}>
                            <ListItemIcon>
                                <Groups />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(routes.ADMIN_FARMS)}>
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary="Farms" />
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(routes.REPORT_LIST)}>
                            <ListItemIcon>
                                <Book />
                            </ListItemIcon>
                            <ListItemText primary="Reports" />
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(routes.WAREHOUSE_LIST)}>
                            <ListItemIcon>
                                <Store />
                            </ListItemIcon>
                            <ListItemText primary="Warehouses" />
                        </ListItemButton>
                    </List>
                </LayoutContainer>
            </PageLayout>
            {/* <FarmsDetails /> */}
        </div>
    )
}

export default AdminPage