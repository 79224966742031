import React from 'react'
import { Card, CardMedia, Grid, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, SvgIcon, Typography } from '@mui/material';
import { IRootState } from '../../../types/IRootState';
import { useSelector } from 'react-redux';
import IAdminState from '../../../types/IAdminState';
import PageLayout from '../PageLayout/PageLayout'
// import Breadcrumb from '../molecules/Breadcrumb'
import statusIcons from '../../../config/statusIcons';
// import { PageHeader } from "../PageHeader/PageHeader";
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import ladangLogo from '../../../assets/logo.png';
import Loader from '../../atoms/Loader/Loader';
import {getHostUrl} from "../../../api/axios/api";
import {ICropsState} from "../../../types/ICropsState";

const ReportsDetails = () => {
    const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
    // const parsedUrl = new URL(window.location.href)
    const hostUrl = getHostUrl()

    const lot_data = useSelector<IRootState>(state => state.admin) as IAdminState
    const lot_data_id = useSelector<IRootState>(state => state.admin) as IAdminState
    const {crops} = useSelector<IRootState>(state => state.crops) as ICropsState

    let details = lot_data?.lot_data.filter((lot: any) => lot.id === lot_data_id?.lot_data_id)[0]
    const crop = crops.find(crop => crop.id === details.crop_id)

    return (
        <div>
            <PageLayout>
                {/* Header */}
                {/* <PageHeader caption="Administration > Reports > Activity > Details" /> */}

                <LayoutContainer className="layout-container-pad0">
                    {/* Info */}
                    <ListItem style={{ backgroundColor: '#6aa84f' }}>
                        <ListItemText primary={'Info'} style={{ color: '#FFFFFF' }} />
                    </ListItem>

                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <SvgIcon component={statusIcons[details.status_icon]} style={{ color: details.status_color }} />
                            </ListItemIcon>
                            <ListItemText primary={details.status} secondary={details.lot} />

                            <ListItemSecondaryAction>
                                <ListItemText primary={details.updated.substr(0, 10)} secondary={details.user} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>

                    {/* Status */}
                    <ListItem style={{ backgroundColor: '#6aa84f' }}>
                        <ListItemText primary={'Status'} style={{ color: '#FFFFFF' }} />
                    </ListItem>

                    <div style={{ padding: 8 }}>
                        {/* Row 1 */}
                        <Grid container justifyContent="space-between">
                            <Grid item xs={4}>
                                <ListItemText primary={'Crop:'} />
                                <ListItemText primary={'Amount:'} />
                                <ListItemText primary={'Weight:'} />
                            </Grid>

                            <Grid item xs={4}>
                                <ListItemText primary={details.farm.split('farm').join('')} />
                                <ListItemText primary={`${details.amount} ${crop?.amount_unit}`} />
                                <ListItemText primary={`${details.weight} ${crop?.weight_unit}`} />
                            </Grid>

                            <Grid item xs={4}>
                                {/* <img style={{
                                margin: 'auto',
                                display: 'block',
                                maxWidth: '100%',
                                maxHeight: '100%'
                            }} alt="img" src={hostUrl + details.image_url} /> */}

                                {details.image_url ? <Card variant="outlined" style={{ maxWidth: '100px', maxHeight: '100px', margin: 'auto' }}>
                                    {/* Loading Animation */}
                                    {imageLoaded ? null : (
                                        <div style={{ width: '100px', height: '100px', paddingTop: 10 }}>
                                            <Loader />
                                        </div>
                                    )}

                                    {/* Render remote image */}
                                    <CardMedia
                                        component="img"
                                        image={hostUrl + details.image_url}
                                        style={imageLoaded ? { width: '100px', height: '100px' } : { display: 'none' }}
                                        onLoad={() => setImageLoaded(true)}
                                    />
                                </Card> :

                                    // render default image
                                    <Card variant="outlined" style={{ position: 'relative', maxWidth: '100px', maxHeight: '100px', margin: 'auto', opacity: 0.6 }}>
                                        <CardMedia
                                            component="img"
                                            image={ladangLogo}
                                            style={{ width: '100px', height: '100px' }}
                                        />
                                        <Typography variant="caption" style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            margin: 'auto',
                                            textAlign: 'center'
                                        }}>
                                            NO IMAGE
                                        </Typography>
                                    </Card>}
                            </Grid>
                        </Grid>

                        {/* Row 2 */}
                        <ListItemText primary={'Notes:'} />
                        <ListItemText primary={details.notes} style={{ whiteSpace: 'pre-line' }} />
                    </div>
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default ReportsDetails
