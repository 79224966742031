import React, {ReactNode} from 'react'
import './PageLayout.scss'
import classNames from 'classnames';
import Content from "../../molecules/Content/Content";
import Header from "../../molecules/Header/Header";
import Footer from "../../molecules/Footer/Footer";

interface Props {
    className?: string;
    children?: ReactNode;
}

const PageLayout = (props: Props) => {
    return (
        <div className={classNames('page-layout', props.className)}>
            <Header/>
            <Content>{props.children}</Content>
            <Footer/>
        </div>
    )
}

export default PageLayout