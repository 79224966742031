import React from 'react'
import './Header.scss'
import classNames from 'classnames';
// import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
// import { ArrowBack } from "@mui/icons-material";
// import { useHistory } from "react-router-dom";
// import Logo from "../../atoms/Logo/Logo";

interface Props {
    className?: string;
}

const Header = (props: Props) => {
    // let history = useHistory();

    return (
        <div className={classNames('header', props.className)}>
            {/* <Logo /> */}
            {/* <AppBar position="static" elevation={2} style={{ background: '#ffffff' }}>
                <Toolbar variant="dense">
                    <IconButton edge="start" color="default" aria-label="back" onClick={() => history.goBack()}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" color="textPrimary">
                        Header
                    </Typography>
                </Toolbar>
            </AppBar> */}
        </div>
    )
}

export default Header