import React, {useEffect, useState} from 'react';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import {Button, Grid, Link, ListItemText, Snackbar, TextField, Typography} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {Navigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../types/IRootState";
import IUserstate from "../../types/IUserState";
import submitLoginAction from '../../state/actions/submitLogin';
import {validateInputs, validations} from '../../config/validations';
import routes from '../../config/routes';
import loadingSetAction from '../../state/actions/loadingSet';
import setLoginDataAction from '../../state/actions/setLoginData';

const LoginPage = (props: any) => {
    const [redirect, setRedirect] = useState('')
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const dispatch = useDispatch()
    const login = useSelector<IRootState>(state => state.user) as IUserstate
    const password = useSelector<IRootState>(state => state.user) as IUserstate
    const auth = useSelector<IRootState>(state => state.user.user?.success) as boolean

    useEffect(() => {
        if (auth) {
            setRedirect(routes.SCAN_QR_PAGE)
        }
    }, [auth])

    const [errors, setErrors] = useState<any>({})
    const resetError = (field: string) => {
        setErrors({
            ...errors,
            [field]: null
        })
    }
    
    const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const handleChangeInput = (id: string, value: string) => {
        resetError(id)
        dispatch(setLoginDataAction({
            [id]: value
        }))
    };

    const submit = () => {
        let data = {
            login: login.login,
            password: password.password
        }

        const errorList = validateInputs({
            'login': [validations.required],
            'password': [validations.required]
        }, data)

        if (Object.keys(errorList).length > 0) {
            setErrors(errorList)
        } else {
            dispatch(loadingSetAction())
            dispatch(submitLoginAction(data))
        }
    }

    return (
        <div className="login-page">
            {redirect && <Navigate to={redirect} replace/>}
            
            <LayoutContainer>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 40,
                    paddingBottom: 40
                }}>
                    <img src="/ladang-logo.png" alt="Ladang" style={{
                        width: '50%',
                        maxWidth: 200
                    }}/>
                </div>

                {/* Email */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Login ID" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="login"
                            placeholder="email or phone"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={login?.login}
                            onChange={evt => handleChangeInput('login',  evt.target.value)}
                            error={errors.login ? true : false}
                        />
                        {errors && <Typography variant="body2" color="secondary">{errors.login}</Typography>}
                    </Grid>
                </Grid>
                <br />

                {/* Password */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Password:" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="password"
                            variant="outlined"
                            size="small"
                            fullWidth
                            type="password"
                            value={password?.password}
                            onChange={evt => handleChangeInput('password',  evt.target.value)}
                            error={errors.password ? true : false}
                        />
                        {errors && <Typography variant="body2" color="secondary">{errors.password}</Typography>}
                        <Grid item style={{marginTop: 30}}>
                            <Button size="small" variant="contained" style={{width: 150}} onClick={()=> submit()}>
                                Login
                            </Button>
                        </Grid>
                        <Grid item style={{marginTop: 10}}>
                            <Link href={routes.FORGOT_PASSWORD} variant="body2">
                                Forgot password
                            </Link>
                        </Grid>
                    </Grid>
                    <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
                        <MuiAlert onClose={handleCloseSnackbar} severity="error">
                            Incorrect email or password.
                        </MuiAlert>
                    </Snackbar>
                </Grid>
            </LayoutContainer>
        </div>
    )
}

export default LoginPage