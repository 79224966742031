import {actionIds, BaseAction} from "../common";
import {IWarehouseState} from "../../types/IWarehouseState";

const defaultState: IWarehouseState = {
    warehouses: [],
    selectedWarehouse: null,
    transactions: [],
    createTransactions: {}
}

const warehouseReducer = (state: IWarehouseState = defaultState, action: BaseAction): IWarehouseState => {
    switch (action.type) {
        case actionIds.SET_WAREHOUSE_LIST:
            return {
                ...state,
                warehouses: action.payload.warehouses
            }

        case actionIds.SET_WAREHOUSE_DETAILS:
            return {
                ...state,
                selectedWarehouse: action.payload.warehouse
            }

        case actionIds.SET_WAREHOUSE_TRANSACTIONS:
            return {
                ...state,
                transactions: action.payload.transactions
            }

        case actionIds.SET_CREATE_WAREHOUSE_TRANSACTIONS_DATA:
            // Remove prefix
            if (action.payload.data.imageData) {
                action.payload.data.imageData = action.payload.data.imageData.split(',')[1]
            }

            return {
                ...state,
                createTransactions: {
                    ...state.createTransactions,
                    ...action.payload.data
                }
            }

        case actionIds.RESET_CREATE_WAREHOUSE_TRANSACTIONS_DATA:
            return {
                ...state,
                createTransactions: {}
            }
            
        default:
            return state
    }
}

export default warehouseReducer;
