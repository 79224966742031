import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Card, CardMedia, Chip, Fab, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, TextField} from '@mui/material';
import {AttachMoney, Close, ContentCut, Delete, OpenWith, PhotoCamera} from '@mui/icons-material';
import PageLayout from "../PageLayout/PageLayout";
import {PageHeader} from "../PageHeader/PageHeader";
import LayoutContainer from "../../atoms/LayoutContainer/LayoutContainer";
import FormDropdown from '../../atoms/FormDropdown';
// import {TabbarLayout, Panel} from '../TabbarLayout/TabbarLayout';
import ButtonCategories from '../TabbarLayout/ButtonCategories';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../../types/IRootState";
import {IWarehouse, IWarehouseInput, IWarehouseState, IWarehouseStock} from "../../../types/IWarehouseState";
import getWarehouseDetailsAction from "../../../state/actions/getWarehouseDetails";
import setCreateTransactionsDataAction from "../../../state/actions/setCreateTransactionsData";
import submitTransactionAction from "../../../state/actions/submitTransaction";
import resetCreateTransactionDataAction from '../../../state/actions/resetCreateTransactionData';
import routes from "../../../config/routes";
import {TWarehouseAction} from "../../../types/TWarehouseAction";
import useMediaPicker from '../../../hooks/useMediaPicker';
import setNotification from "../../../state/actions/setNotification";

interface Props {
}

interface UrlParams {
    id?: string | undefined;
}

interface UrlState {
    stockId?: number;
}

const buttonCategories = [
    {
        name: 'Harvested',
        icon: ContentCut,
        action: 'harvest'
    },
    {
        name: 'Purchased',
        icon: AttachMoney,
        action: 'buy'
    }
]

const buttonCategories2= [
    {
        name: 'Move',
        icon: OpenWith,
        action: 'move'
    },
    {
        name: 'Trash',
        icon: Delete,
        action: 'trash'
    },
    {
        name: 'Sold',
        icon: AttachMoney,
        action: 'sell'
    }
]

const WarehouseCreateTransaction = (props: Props) => {
    const navigate = useNavigate()
    let { id } = useParams() as UrlParams
    const dispatch = useDispatch()
    const location = useLocation()
    const selectedWarehouseStock = location.state as UrlState
    const {warehouses, selectedWarehouse} = useSelector<IRootState>(state => state.warehouse) as IWarehouseState
    const {
        direction = 'in',
        action = 'harvest',
        amount,
        weight,
        imageData,
        notes,
        price,
        warehouse_stock_id,
        target_warehouse_id
    } = useSelector<IRootState>(state => state.warehouse.createTransactions) as IWarehouseInput
    const [cropId, setCropId] = useState(0)
    const [warehouseStock, setWarehouseStock] = useState<IWarehouseStock | undefined>(undefined)
    const [warehouseId, setWarehouseId] = useState(0)
    const [targetWarehouse, setTargetWarehouse] = useState<IWarehouse[]>([])
    const [selectedButton, setSelectedButton] = useState('Harvested')
    const [selectedButton2, setSelectedButton2] = useState('Move')

    const {handleOpenGallery} = useMediaPicker((image) => handleChangeSelectImage(image))

    useEffect(() => {
        if(id) {
            if (!selectedWarehouse || selectedWarehouse.id !== parseInt(id)) {
                dispatch(getWarehouseDetailsAction(parseInt(id)))
            }

            let warehouseId = id
            setTargetWarehouse(warehouses?.filter(
                (warehouse) => warehouse.id !== parseInt(warehouseId)
            ))

            // reset createTransactions when page refresh, fixed action input & selectedButton state conflict
            dispatch(resetCreateTransactionDataAction())
        }

        // preselects crop
        if (selectedWarehouseStock) {
            handleChangeCrop(selectedWarehouseStock.stockId)
        }

    }, [dispatch, id, selectedWarehouse, warehouses, selectedWarehouseStock])

    if (!id) {
        navigate(routes.WAREHOUSE_LIST)
        return null
    }

    const handleChangeCrop = (stockId) => {
        if (stockId === '') {
            setCropId(0)
            setWarehouseStock(undefined)

            // warehouse_stock_id
            dispatch(setCreateTransactionsDataAction({
                warehouse_stock_id: undefined
            }))
        } else {
            setCropId(stockId)
            setWarehouseStock(selectedWarehouse?.stock?.find(
                (stock: IWarehouseStock) => stock.id === stockId
            ))

            // warehouse_stock_id
            dispatch(setCreateTransactionsDataAction({
                warehouse_stock_id: stockId
            }))
        }
    }

    const handleChangeWarehouse = (evt) => {
        if (evt.target.value === '') {
            setWarehouseId(0)

            // target_warehouse_id
            dispatch(setCreateTransactionsDataAction({
                target_warehouse_id: 0
            }))
        } else {
            setWarehouseId(parseInt(evt.target.value))

            // target_warehouse_id
            dispatch(setCreateTransactionsDataAction({
                target_warehouse_id: parseInt(evt.target.value)
            }))
        }
    }

    const handleChangeDirection = (evt) => {
        dispatch(setCreateTransactionsDataAction({
            direction: evt.currentTarget.id
        }))

        // dispatch transaction action value when switch direction
        if (evt.currentTarget.id === 'in') {
            let action = buttonCategories.find((button) => button.name === selectedButton)?.action
            dispatch(setCreateTransactionsDataAction({
                action: action
            }))
        } else {
            let action = buttonCategories2.find((button) => button.name === selectedButton2)?.action
            dispatch(setCreateTransactionsDataAction({
                action: action
            }))        
        }
    };

    const handleChangeSelectImage = (image) => {
        if (image === 'error') {
            alert('Error: Unsupported file format')
        } else {
            dispatch(setCreateTransactionsDataAction({
                imageData: image
            }))
        }
    }

    const handleChangeButtonCategry = (type: string, buttonCategoryName: string, action: TWarehouseAction) => {
        if (type === 'buttonCategory') setSelectedButton(buttonCategoryName)
        if (type === 'buttonCategory2') setSelectedButton2(buttonCategoryName)

        dispatch(setCreateTransactionsDataAction({
            action: action
        }))
    }

    const handleChangeInput = (id: string, value: string | number) => {
        dispatch(setCreateTransactionsDataAction({
            [id]: value
        }))
    };

    const handleSubmit = () => {
        if (!warehouse_stock_id) {
            dispatch(setNotification({
                type: 'error',
                message: 'Please select crop type'
            }))
            return null
        } else if(action === 'move' && !target_warehouse_id) {
            dispatch(setNotification({
                type: 'error',
                message: 'Please select a target warehouse'
            }))
            return null
        } else if((action === 'buy' || action === 'sell') && !price) {
            dispatch(setNotification({
                type: 'error',
                message: 'Please enter price'
            }))
            return null
        } else if(!weight && !amount) {
            dispatch(setNotification({
                type: 'error',
                message: 'Please enter weight or amount'
            }))
            return null
        }

        dispatch(submitTransactionAction({
            direction,
            action,
            amount,
            weight,
            imageData,
            notes,
            price,
            warehouse_stock_id,
            target_warehouse_id
        }))
        dispatch(resetCreateTransactionDataAction())
        navigate(routes.WAREHOUSE_LIST)
    }

    const disableSubmit = () => {
        if (!weight || !amount) {
            return true
        } else if ((action === 'buy' || action === 'sell') && !price) {
            return true
        }
        return false
    }

    return (
        <PageLayout>
            <PageHeader caption="Management / Warehouses / Create Transaction"/>
            <LayoutContainer>
                <List style={{background: '#f4f4f4'}}>
                    <ListItem>
                        <ListItemText primary={selectedWarehouse?.name}/>
                    </ListItem>
                </List>

                {/* Crop */}
                <FormDropdown 
                    label={'Crop'} 
                    value={cropId} 
                    options={selectedWarehouse?.stock} 
                    onChange={evt => handleChangeCrop(parseInt(evt.target.value))}
                />

                <div style={{marginTop: 10}}/>

                {/* Category */}
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={6}>
                        <Chip 
                            id="in" 
                            variant={direction === 'in' ? 'filled' : 'outlined'} 
                            color="primary" 
                            label="Incoming" 
                            onClick={handleChangeDirection}
                            style={{width: '100%'}}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Chip 
                            id="out" 
                            variant={direction === 'out' ? 'filled' : 'outlined'} 
                            color="primary" 
                            label="Outgoing" 
                            onClick={handleChangeDirection}
                            style={{width: '100%'}}
                        />
                    </Grid>
                </Grid>

                {direction === 'in' &&
                    <ButtonCategories 
                        data={buttonCategories} 
                        selectedButton={selectedButton} 
                        onClick={(buttonCategory) => handleChangeButtonCategry('buttonCategory', buttonCategory.name, buttonCategory.action)} 
                    />
                }

                {direction === 'out' && 
                    <ButtonCategories 
                        data={buttonCategories2} 
                        selectedButton={selectedButton2} 
                        onClick={(buttonCategory2) => { handleChangeButtonCategry('buttonCategory2', buttonCategory2.name, buttonCategory2.action) }}
                    />
                }

                {/* Price */}
                {((direction === 'in' && selectedButton === 'Purchased') || (direction === 'out' && selectedButton2 === 'Sold')) && <>
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <ListItemText primary="Price:"/>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                id="price"
                                variant="outlined"
                                size="small"
                                type="number"
                                fullWidth
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><>{'price'}</></InputAdornment>
                                }}
                                value={price === 0 || price ? price : ''}
                                onChange={evt => handleChangeInput('price', parseFloat(evt.target.value))}
                            />
                        </Grid>
                    </Grid>
                    <div>&nbsp;</div>
                </>}

                {/* New Warehouse */}
                {selectedButton2 === 'Move' && direction === 'out' && <>
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <ListItemText primary="New Warehouse:"/>
                        </Grid>
                        <Grid item xs={8}>
                            <FormDropdown
                                label={'New Warehouse'}
                                value={warehouseId}
                                options={targetWarehouse}
                                onChange={handleChangeWarehouse}
                            />
                        </Grid>
                    </Grid>
                    <div>&nbsp;</div>
                </>}
                
                <Grid container spacing={2} alignItems="center">
                    {/* Weight */}
                    <Grid item xs={6}>
                        <ListItemText primary="Weight:"/>
                        <TextField
                            id="weight"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><>{warehouseStock?.weight_unit}</></InputAdornment>
                            }}
                            value={weight === 0 || weight ? weight : ''}
                            onChange={evt => handleChangeInput('weight',  parseFloat(evt.target.value))}
                        />
                    </Grid>
                    {/* Amount */}
                    <Grid item xs={6}>
                        <ListItemText primary="Amount:"/>
                        <TextField
                            id="amount"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end"><>{warehouseStock?.amount_unit}</></InputAdornment>
                            }}
                            value={amount === 0 || amount ? amount : ''}
                            onChange={evt => handleChangeInput('amount', parseFloat(evt.target.value))}
                        />
                    </Grid>
                </Grid>

                <div>&nbsp;</div>   

                {/* Image */}
                <ListItemText primary="Photo: (optional)"/>

                {!imageData ?
                    <Card variant="outlined" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200,
                        position: 'relative'
                    }}>
                        <label htmlFor="icon-button-file">
                            <input id="icon-button-file"
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleOpenGallery}
                            />
                            <IconButton component="span">
                                <PhotoCamera style={{fontSize: 40}}/>
                            </IconButton>
                        </label>
                    </Card> :
                    <Card variant="outlined" style={{height: 200, position: 'relative'}}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={'data:image/jpeg;base64,' + imageData}
                        />

                        <Fab color="primary" size="small" aria-label="close" style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: '#F50A00'
                        }} onClick={() => {
                            dispatch(setCreateTransactionsDataAction({
                                imageData: ''
                            }))
                        }}>
                            <Close/>
                        </Fab>
                    </Card>}

                <div style={{marginTop: 15}}/>

                {/* Notes */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                        <ListItemText primary="Notes: (optional)"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="notes"
                            variant="outlined"
                            size="small"
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={4}
                            value={notes || ''}
                            onChange={evt => handleChangeInput('notes', evt.target.value)}
                        />
                    </Grid>
                </Grid>

                <div style={{marginTop: 10}}/>

                <Button
                    size="small"
                    variant="contained"
                    style={{width: 150}}
                    // disabled={disableSubmit()}
                    onClick={() => handleSubmit()}
                >
                    Save
                </Button>
            </LayoutContainer>
            &nbsp;&nbsp;
        </PageLayout>
    )
}

export default WarehouseCreateTransaction