import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {
    Card,
    CardMedia,
    Dialog,
    FormControl,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    Slide,
    SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Assessment, Close, PhotoLibrary, ShowChart} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
// import {ArgumentAxis, BarSeries, Chart, ValueAxis} from '@devexpress/dx-react-chart-material-ui';
// import {Palette} from '@devexpress/dx-react-chart';
import { Chart, Legend, Series } from 'devextreme-react/chart';
import PageLayout from '../PageLayout/PageLayout'
import statusIcons from '../../../config/statusIcons';
import getReportsActivityAction from "../../../state/actions/getReportsActivity";
import setAdminDataAction from "../../../state/actions/setAdminData";
import setReportsQueryAction from "../../../state/actions/setReportsQuery";
import unsetReportsQueryAction from "../../../state/actions/unsetReportsQuery";
import unsetReportsActivityAction from "../../../state/actions/unsetReportsActivity";
import {PageHeader} from "../PageHeader/PageHeader";
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../atoms/Loader/Loader';
import {getHostUrl} from "../../../api/axios/api";
import ButtonCategories from '../TabbarLayout/ButtonCategories';
import FormInput from '../../atoms/FormInput';
import {TransitionProps} from '@mui/material/transitions';
import ReportsDetails from './ReportsDetails';
import {IRootState} from '../../../types/IRootState';
import IUserState from '../../../types/IUserState';
import IAdminState from '../../../types/IAdminState';
import {TQueryAction, IReportState} from '../../../types/IReportState';

// Dialog Transition
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const buttonCategories = [
    {
        name: 'Updates',
        icon: Assessment
    },
    {
        name: 'Gallery',
        icon: PhotoLibrary
    },
    {
        name: 'Graph',
        icon: ShowChart
    }
]

const Reports = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const desktopViewport = useMediaQuery(theme.breakpoints.up('sm'));

    const {users} = useSelector<IRootState>(state => state.user) as IUserState
    const {farms, zones} = useSelector<IRootState>(state => state.admin) as IAdminState
    const {reportQuery, lot_data, has_more, image_url, graph_data, graph_table_data} = useSelector<IRootState>(state => state.report) as IReportState

    const [open, setOpen] = useState(false)
    const [selectedButton, setSelectedButton] = useState('Updates')
    const scrollTarget = useRef<number>(0)
    const isMounted = useRef(false)

    useEffect(() => {
        if (isMounted.current === false) {
            dispatch(getReportsActivityAction(reportQuery))
            return () => {
                isMounted.current = true
            }
        }
    }, [dispatch, reportQuery])

    useEffect(() => {
        return () => {
            dispatch(unsetReportsActivityAction())
            dispatch(unsetReportsQueryAction())
        }
    }, [dispatch])

    useLayoutEffect(() => {
        if (selectedButton === 'Updates') { 
            const div = document.getElementById('scrollableDiv');
            div?.scrollTo(0, scrollTarget.current)
        }
    }, [selectedButton])

    const hostUrl = getHostUrl();

    const handleSelectCrop = (id) => {
        dispatch(setAdminDataAction({
            lot_data_id: id
        }))
    }

    const handleOpenDialog = () => {
        setOpen(!open)
    }

    const handleChangeButton = (buttonCategoryName: string) => {
        setSelectedButton(buttonCategoryName)
    }

    const handleScroll = (event: React.UIEvent<HTMLElement>) => {
        const { scrollTop } = event.currentTarget
        scrollTarget.current = scrollTop
    }

    const handleInfiniteScroll = () => {
        dispatch(getReportsActivityAction(reportQuery))
    }

    const handleChangeInput = (id: TQueryAction, value: string | number) => {
        dispatch(unsetReportsActivityAction())
        dispatch(setReportsQueryAction({
            [id]: value
        }))
        isMounted.current = false
    }

    return (
        <div>
            <PageLayout>
                {/* Header */}
                <PageHeader caption="Administration > Reports > Activity" />

                <LayoutContainer className="layout-container-pad0">
                    <div style={{ padding: '0px 8px 8px' }}>
                        {/* Row 1 */}
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={4}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Select
                                        id="farm_id"
                                        value={reportQuery.farm_id}
                                        onChange={evt => handleChangeInput('farm_id', evt.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'select' }}
                                    >
                                        <MenuItem value={0}>
                                            <em>Farm</em>
                                        </MenuItem>
                                        {farms?.map(option => {
                                            return (
                                                <MenuItem key={option?.id} value={option?.id}>
                                                    {option?.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Select
                                        id="zone_id"
                                        value={reportQuery.zone_id}
                                        onChange={evt => handleChangeInput('zone_id', evt.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'select' }}
                                    >
                                        <MenuItem value={0}>
                                            <em>Zone</em>
                                        </MenuItem>
                                        {zones && zones.filter(zone => zone.farm_id === reportQuery.farm_id || reportQuery.farm_id === 0).map(option => {
                                            return (
                                                <MenuItem key={option?.id} value={option?.id}>
                                                    {option?.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Select
                                        id="user_id"
                                        value={reportQuery.user_id}
                                        onChange={evt => handleChangeInput('user_id', evt.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'select' }}
                                    >
                                        <MenuItem value={0}>
                                            <em>Worker</em>
                                        </MenuItem>
                                        {users?.map(option => {
                                            return (
                                                <MenuItem key={option?.id} value={option?.id}>
                                                    {option?.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* Row 2 */}
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={6}>
                                <FormInput
                                    id="startDate"
                                    label="From:"
                                    type="date"
                                    size={12}
                                    value={reportQuery.startDate}
                                    onChange={evt => handleChangeInput('startDate', evt.target.value)}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormInput
                                    id="endDate"
                                    label="To:"
                                    type="date"
                                    size={12}
                                    value={reportQuery.endDate}
                                    onChange={evt => handleChangeInput('endDate', evt.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    {/* Button Categories */}
                    <ButtonCategories 
                        data={buttonCategories} 
                        selectedButton={selectedButton} 
                        onClick={(buttonCategory) => handleChangeButton(buttonCategory.name)} 
                    />

                    {selectedButton === 'Updates' && <>
                        {/* Last updates */}
                        <ListItem style={{ backgroundColor: '#6aa84f' }}>
                            <ListItemText primary={'Last updates'} style={{ color: '#FFFFFF' }} />
                        </ListItem>

                        <div id="scrollableDiv" style={{ height: window.innerHeight - 380, overflowY: "scroll" }} onScroll={handleScroll}>
                            <InfiniteScroll
                                dataLength={lot_data.length} //This is important field to render the next data
                                next={() => handleInfiniteScroll()}
                                hasMore={has_more}
                                loader={<Loader />}
                                scrollableTarget="scrollableDiv"
                                endMessage={
                                    <p style={{ textAlign: 'center' }}>
                                        <b>END OF PAGE</b>
                                    </p>
                                }
                            >
                                {lot_data.map((data, i) => {
                                    return (
                                        <List key={i} style={{ background: i % 2 ? '#f4f4f4' : '#FFFFFF', cursor: 'pointer' }}>
                                            <ListItem onClick={() => { handleSelectCrop(data.id); handleOpenDialog(); }}>
                                                <ListItemIcon>
                                                    <SvgIcon component={statusIcons[data.status_icon]} style={{ color: data.status_color }} />
                                                </ListItemIcon>
                                                <ListItemText primary={data.status} secondary={data.lot + ' / Zone: ' + data.zone} />

                                                <ListItemSecondaryAction>
                                                    <ListItemText primary={(data.updated).substr(0, 10)} secondary={data.user} />
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    )
                                })}
                            </InfiniteScroll>
                        </div>
                    </>}

                    {selectedButton === 'Gallery' && <>
                        <ListItem style={{ backgroundColor: '#6aa84f' }}>
                            <ListItemText primary={'Activity Gallery'} style={{ color: '#FFFFFF' }} />
                        </ListItem>

                        <div style={{ padding: 10 }}> {/* Negative margin Limitations*/}
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                {image_url.map((data, i) => {
                                    return (
                                        <Grid item xs={4} key={i}>
                                            <Card variant="outlined" style={{ maxWidth: '100px', maxHeight: '100px', margin: 'auto' }}>
                                                {data.image_url !== '' && <CardMedia
                                                    component="img"
                                                    image={hostUrl + data.image_url}
                                                    style={{ width: '100px', height: '100px' }}
                                                    onClick={() => { handleSelectCrop(data.id); handleOpenDialog(); }}
                                                />}
                                            </Card>
                                        </Grid>
                                    )
                                })}
                                {image_url.length === 0 && <ListItemText primary={'Information not available'} style={{ textAlign: 'center'}} />}
                            </Grid>
                        </div>
                    </>}

                    {selectedButton === 'Graph' && <>
                        <div style={{ padding: '0px 8px 8px' }}>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        {/* Graph */}
                                        <ListItem style={{ backgroundColor: '#6aa84f' }}>
                                            <ListItemText primary={'Updates per day'} style={{ color: '#FFFFFF' }} />
                                        </ListItem>
                                        {/* dev express */}
                                        {/* <Chart */}
                                            {/* data={graph_data} */}
                                            {/* height={desktopViewport ? 350 : window.innerHeight - 380} */}
                                        {/* > */}
                                            {/* <ArgumentAxis /> */}
                                            {/* <ValueAxis /> */}

                                            {/* <Palette scheme={['#42a5f5']} /> */}

                                            {/* <BarSeries */}
                                                {/* valueField="total" */}
                                                {/* argumentField="date" */}
                                            {/* /> */}

                                            {/* Enable Zoom/Pan and Animation */}
                                            {/* <ZoomAndPan /> */}
                                            {/* <Title text={`Total harvested in ${mockData2[0].year}`} /> */}
                                            {/* <Animation /> */}
                                        {/* </Chart> */}

                                        {/* dev extreme */}
                                        <Chart 
                                            id="chart" 
                                            dataSource={graph_data}
                                            style={{
                                                height: desktopViewport ? 350 : window.innerHeight - 380
                                            }}
                                        >
                                            <Series
                                                valueField="total"
                                                argumentField="date"
                                                name="Updates per day"
                                                type="bar"
                                                color="#42a5f5" 
                                            />
                                            <Legend visible={false} verticalAlignment="bottom" horizontalAlignment="center" />
                                        </Chart>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Card>
                                        <ListItem style={{ backgroundColor: '#6aa84f' }}>
                                            <ListItemText primary={'List of daily updates'} style={{ color: '#FFFFFF' }} />
                                        </ListItem>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell align="right">Amount</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {graph_table_data.map((row) => (
                                                    <TableRow
                                                        key={row.date}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.date}
                                                        </TableCell>
                                                        <TableCell align="right">{row.total}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </>}

                <Dialog 
                    fullScreen
                    open={open}
                    onClose={handleOpenDialog}
                    TransitionComponent={Transition}               
                    BackdropProps={{ style: { backgroundColor: "transparent" } }}
                >
                    {/* Page Header */}
                    <List disablePadding style={{ display: 'flex', flexDirection: 'row' }}>
                        <ListItemButton onClick={handleOpenDialog} style={{ backgroundColor: '#073763', width: 62 }}>
                            <ListItemIcon>
                                <Close style={{
                                    position: 'relative',
                                    left: 2,
                                    width: '25px',
                                    height: '25px',
                                    color: '#073763',
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: '50%'
                                }} />
                            </ListItemIcon>
                        </ListItemButton>

                        <ListItem style={{ backgroundColor: '#2b78e4' }}>
                            <ListItemText primary={'Administration > Reports > Details'} style={{ color: '#FFFFFF' }} />
                        </ListItem>
                    </List>
                    
                    {/* ReportsDetails Overlay */}
                    <ReportsDetails />
                </Dialog>
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default Reports