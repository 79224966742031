import React, {useEffect} from 'react'
import './Notifications.scss'
import classNames from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';
import Notification from '../../atoms/Notification/Notification';
import {IRootState} from "../../../types/IRootState";
import clearNotificationsAction from '../../../state/actions/clearNotifications';

interface Props {
    className?: string;
}

const Notifications = (props: Props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const {notifications} = useSelector((state: IRootState) => state.notifications)

    useEffect(() => {            
        dispatch(clearNotificationsAction())
    }, [dispatch, location.pathname])

    return (
        <div className={classNames(
            `notifications ${notifications.length > 0}`,
            props.className,
        )}>
            {notifications.map(notification => <Notification key={String(notification.created)} notification={notification}></Notification>)}
        </div>
    )
}

export default Notifications