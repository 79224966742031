import React from 'react';
import {
    Button,
    Card,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    SvgIcon,
    Typography
} from '@mui/material'
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from '../../../types/IRootState';
import IHarvestState from "../../../types/IHarvestState";
import setHarvestDataAction from '../../../state/actions/setHarvestData';
import {useNavigate} from 'react-router-dom';
import routes from '../../../config/routes';
import statusIcons from '../../../config/statusIcons';
import {PageHeader} from "../PageHeader/PageHeader";
import {ICrop, ICropsState} from "../../../types/ICropsState";
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';

const UpdateStatus2 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        lot,
        crop_id,
        crop_index,
    } = useSelector<IRootState>(state => state.harvest) as IHarvestState
    const cropsState = useSelector<IRootState>(state => state.crops) as ICropsState

    let crops: ICrop[]
    if(!cropsState) {
        alert('There is a new version available, please log out and log in again to refresh the app')
        navigate(routes.PROFILE)
        return null
    } else {
        crops = cropsState.crops
    }

    const crop = crops.find(crop => crop.id.toString(10) === crop_id)
    if (!crop) {
        alert('Error: Could not find crops for id, try to log out and log in again to refresh your data')
        navigate(routes.PROFILE)
        return null
    }

    const handleChangeStatus = (status_id) => {
        dispatch(setHarvestDataAction({
            status_id: status_id
        }))
        navigate(routes.UPDATE_STATUS_PAGE_3)
    }

    return (
        <div>
            {/* Header */}
            <List disablePadding style={{ background: '#f4f4f4' }}>
                <ListItem disableGutters>
                    <ListItemText
                        primary={<Typography variant="h5" align="center" style={{ marginTop: -2, marginBottom: -5 }}>{lot?.zone}</Typography>}
                        secondary={<Typography variant="body2" align="center">Zone</Typography>}
                        style={{ textAlign: 'center' }}
                    />

                    <Divider orientation="vertical" flexItem />

                    <ListItemText primary={lot?.name} style={{ paddingLeft: 15 }} />
                </ListItem>
            </List>

            {/* Lot Action Status */}
            <PageHeader caption="Lot Actions > Update Status (2/3)"/>

            {/* Progress Bar */}
            <List style={{ background: '#f4f4f4' }}>
                <ListItem>
                    <ListItemText primary={`Tree #${crop_index}`} />
                    <ListItemText primary={<ProgressBar variant="determinate" value={60} />} />
                </ListItem>
            </List>

            <Card variant="outlined">
                <Grid container>
                    {crop?.statuses?.map((status, i) => {
                        return (
                            <Grid item xs={6} key={i}>
                                {/* <ListItem
                                    button
                                    dense
                                    disableGutters
                                    style={{
                                        display: 'flex',
                                        // alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                    <ListItemIcon>
                                        <ArrowRight style={{ color: status.color }} />
                                    </ListItemIcon>
                                    <ListItemText primary={status.name} style={{ color: status.color }} />
                                </ListItem> */}

                                <Button
                                    sx={{
                                        height: 95,
                                        flexDirection: 'column'
                                    }}
                                    fullWidth
                                    onClick={() => { handleChangeStatus(status.id) }}
                                >
                                    <Stack alignItems="center">
                                        <SvgIcon component={statusIcons[status.icon]} style={{ color: status.color }} />
                                        <ListItemText primary={status.name} style={{ color: status.color }} />
                                    </Stack>
                                </Button>
                            </Grid>
                        )
                    })}
                </Grid>
            </Card>
        </div>
    )
}

export default UpdateStatus2
