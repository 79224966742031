import {actionIds, BaseAction} from "../common";
import INotification from "../../types/INotification";

const setNotificationAction: (data: INotification) =>
    BaseAction = (data) => ({
    type: actionIds.SET_NOTIFICATION,
    payload: {
        ...data,
    },
});

export default setNotificationAction;
