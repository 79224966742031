import {useSelector} from "react-redux";
import {IRootState} from "../../types/IRootState";
import IUserState from "../../types/IUserState";
import React, {useState} from "react";
import PageLayout from "../organisms/PageLayout/PageLayout";
import {PageHeader} from "../organisms/PageHeader/PageHeader";
import LayoutContainer from "../atoms/LayoutContainer/LayoutContainer";
import {APP_VERSION} from "../../config/version";
import {Alert, LinearProgress, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ErrorOutline, Memory, QrCode2, Settings, Storage} from "@mui/icons-material";
import {useNavigate} from 'react-router-dom';
import routes from "../../config/routes";
import {getUnsyncedHarvestDataCount, listHarvestDataArray} from "../../config/db";
import {debugReport} from "../../api/axios/api";
import './DebugPage.scss'
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

type TSubmitReportStatus = 'success' | 'in_progress' | 'error' | 'pending'

interface ISubmitReportDetails {
    status: TSubmitReportStatus;
    submitted: number;
    total: number;
    message: string;
}

const DebugPage = () => {
    const navigate = useNavigate()
    const {gpsPosition} = useSelector<IRootState>(state => state.user) as IUserState

    const gpsCoordinates = gpsPosition?.latitude + ',' + gpsPosition?.longitude
    const [debugReportSubmitDetails, setDebugReportSubmitDetails] = useState<ISubmitReportDetails>({
        status: 'pending',
        submitted: 0,
        total: 0,
        message: ''
    });

    const sendDebugData = async () => {
        const total = await getUnsyncedHarvestDataCount()
        let submitted = 0
        let hasError = false
        let error = ''
        setDebugReportSubmitDetails({
            status: 'in_progress',
            submitted: submitted,
            total: total,
            message: ''
        })

        console.log('Sending debug data')
        const dbData = await listHarvestDataArray()
        while (!hasError && submitted < total) {
            const data = dbData.shift()
            try {
                const response = await debugReport({
                    app_version: APP_VERSION,
                    indexed_db: data ? JSON.stringify(data) : '',
                    partial_index: submitted,
                    partial_total: total
                })
                if (response['success']) {
                    submitted++;
                    setDebugReportSubmitDetails({
                        ...debugReportSubmitDetails,
                        status: 'in_progress',
                        submitted: submitted,
                        total: total,
                    })
                } else {
                    error = response['error']
                    hasError = true
                    setDebugReportSubmitDetails({
                        ...debugReportSubmitDetails,
                        status: 'error',
                        message: error
                    })
                }
            } catch (e: any) {
                error = e.message
                hasError = true
                setDebugReportSubmitDetails({
                    ...debugReportSubmitDetails,
                    status: 'error',
                    message: e.message
                })
            }
        }
        if (!hasError) {
            setDebugReportSubmitDetails({
                ...debugReportSubmitDetails,
                status: 'success'
            })
        }
    }

    const handleServiceWorker = () => {
        if ('serviceWorker' in navigator) {
            serviceWorkerRegistration.unregister()

            caches.keys().then(cacheNames => {
                cacheNames.forEach(cacheName => {
                    caches.delete(cacheName)
                })
            }).then(() => {
                serviceWorkerRegistration.register()
            })

            setTimeout(() => {
                window.location.replace('')
            }, 300)
        }
    }

    return (
        <div className="debug-page">
            <PageLayout>
                <PageHeader caption="Debug"/>
                <LayoutContainer>
                    {debugReportSubmitDetails.status === 'success' && <div>
                        <div>&nbsp;</div>
                        <Alert severity="success">Successfully transmitted debug data to server</Alert>
                    </div>}
                    {debugReportSubmitDetails.status === 'in_progress' && <div>
                        <div>&nbsp;</div>
                        <Alert severity="info">Sending data to server in progress...<br/>
                            <LinearProgress variant="determinate"
                                            value={debugReportSubmitDetails.total > 0 ? debugReportSubmitDetails.submitted / debugReportSubmitDetails.total * 100 : 0}/>
                        </Alert>
                    </div>}
                    {debugReportSubmitDetails.status === 'error' && <div>
                        <div>&nbsp;</div>
                        <Alert severity="error">Failed to transmit debug data to server: <br />{debugReportSubmitDetails.message}</Alert>
                    </div>}
                    <div>
                        <div>&nbsp;</div>
                        <Alert severity="info">GPS location: {gpsCoordinates}</Alert>
                    </div>
                    <List>
                        <ListItemButton onClick={() => sendDebugData()}>
                            <ListItemIcon>
                                <Settings/>
                            </ListItemIcon>
                            <ListItemText primary="Send debug data"/>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(routes.DEBUG_ERROR_LOGS)}>
                            <ListItemIcon>
                                <ErrorOutline/>
                            </ListItemIcon>
                            <ListItemText primary="Error logs"/>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(routes.DEBUG_SCANNED_DATA)}>
                            <ListItemIcon>
                                <QrCode2/>
                            </ListItemIcon>
                            <ListItemText primary="Scanned data"/>
                        </ListItemButton>
                        <ListItemButton onClick={() => navigate(routes.DEBUG_LOG_DATA_HISTORY)}>
                            <ListItemIcon>
                                <Storage/>
                            </ListItemIcon>
                            <ListItemText primary="Log data history"/>
                        </ListItemButton>
                        <ListItemButton onClick={() => handleServiceWorker()}>
                            <ListItemIcon>
                                <Memory/>
                            </ListItemIcon>
                            <ListItemText primary="Clear SW cache"/>
                        </ListItemButton>
                    </List>
                    <div className="profile-page__version">Version: {APP_VERSION}</div>
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default DebugPage
