import {List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ArrowLeft} from "../../atoms/ArrowLeft";
import {useNavigate} from "react-router-dom";

interface Props {
    caption: string;
}

export const PageHeader = (props: Props) => {
    let navigate = useNavigate();

    return (
        <List disablePadding style={{ display: 'flex', flexDirection: 'row' }}>
            <ListItemButton onClick={() => { navigate(-1) }} style={{ backgroundColor: '#073763', width: 62 }}>
                <ListItemIcon>
                    <ArrowLeft />
                </ListItemIcon>
            </ListItemButton>

            <ListItem style={{ backgroundColor: '#2b78e4' }}>
                <ListItemText primary={props.caption} style={{ color: '#FFFFFF' }} />
            </ListItem>
        </List>
    )
}