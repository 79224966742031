import React from 'react';
import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import {IRootState} from '../../types/IRootState';
import IAdminState from '../../types/IAdminState';
import getFarmsDetailsAction from '../../state/actions/getFarmsDetails';
import setFarmSelectedAction from '../../state/actions/setFarmSelected';
import {PageHeader} from "./PageHeader/PageHeader";
import PageLayout from "./PageLayout/PageLayout";
import {ArrowRight} from "../atoms/ArrowRight";

const Farms = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {farms} = useSelector<IRootState>(state => state.admin) as IAdminState

    const handleFarmDetails = (id) => {
        navigate('/administration/farms/zones')
        dispatch(getFarmsDetailsAction(id))
        dispatch(setFarmSelectedAction({selectedFarm: id}))
    }

    return (
        <PageLayout>
            <PageHeader caption="Management / Farms"/>
            <LayoutContainer>
                {farms.map((item, index) => {
                    return (
                        <List key={index}>
                            <ListItem component="div">
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={item.name} />
                                </ListItem>
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={`${item.zones_count} Zones`} style={{ textAlign: 'right' }} />
                                </ListItem>
                                <ListItemSecondaryAction onClick={() => {handleFarmDetails(item.id)}}>
                                    <IconButton edge="end" aria-label="edit" >
                                        <ArrowRight color={'currentColor'} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                })}
            </LayoutContainer>
        </PageLayout>
    )
}

export default Farms