import React, {useState} from 'react';
import PageLayout from "../PageLayout/PageLayout";
import {PageHeader} from "../PageHeader/PageHeader";
import LayoutContainer from "../../atoms/LayoutContainer/LayoutContainer";
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from "../../../types/IRootState";
import {IWarehouseState, IWarehouseStock, IWarehouseTransactions} from "../../../types/IWarehouseState";
import getWarehouseDetailsAction from "../../../state/actions/getWarehouseDetails";
import {FormControl, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, MenuItem, Select, SvgIcon, TextField} from "@mui/material";
import {Cached} from '@mui/icons-material';
import {format, subMonths} from "date-fns";
import {useNavigate, useParams} from "react-router-dom";
import routes from "../../../config/routes";
import statusIcons from '../../../config/statusIcons';
import getWarehouseTransactionsAction from '../../../state/actions/getWarehouseTransactions';

interface Props {
}

interface UrlParams {
    id?: string | undefined;
}

const WarehouseDetails = (props: Props) => {
    const navigate = useNavigate()
    let { id } = useParams() as UrlParams
    const dispatch = useDispatch()
    const {selectedWarehouse} = useSelector<IRootState>(state => state.warehouse) as IWarehouseState
    const {transactions} = useSelector<IRootState>(state => state.warehouse) as IWarehouseState
    const [warehouseStock, setWarehouseStock] = useState<IWarehouseStock | undefined>(undefined)
    const [cropId, setCropId] = useState(0)

    const oneMonthAgo = subMonths(new Date(), 1)
    const [startDate, setStartDate] = useState(format(oneMonthAgo, 'yyyy-MM-dd'))
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))

    const handleChangeCrop = (evt) => {
        setCropId(parseInt(evt.target.value))
        setWarehouseStock(selectedWarehouse?.stock?.find(
            (stock: IWarehouseStock) => stock.id === parseInt(evt.target.value)
        ))
    }

    const handleChangeDate = (evt) => {
        switch (evt.target.id) {
            case 'startDate':
                setStartDate(evt.target.value)
                break;

            case 'endDate':
                setEndDate(evt.target.value)
                break;

            default:
                break;
        }
    }

    const filterTransactions = (startDate: string, endDate: string, dataList: IWarehouseTransactions[]) => {
        if (!dataList) {
            return [];
        }
        return dataList.filter((data: IWarehouseTransactions) =>
            format(new Date(data.updated), 'yyyy-MM-dd') >= startDate && 
            format(new Date(data.updated), 'yyyy-MM-dd') <= endDate
        )
    }

    const handleWarehouseCreateTransaction = (id, stockId) => {
        navigate(routes.WAREHOUSE_CREATE_TRANSACTION.replace(':id', id), {
            state: {
                stockId: stockId
            }
        })
    }

    React.useEffect(() => {
        if(id) {
            dispatch(getWarehouseTransactionsAction(parseInt(id)))

            if (!selectedWarehouse || selectedWarehouse.id !== parseInt(id)) {
                dispatch(getWarehouseDetailsAction(parseInt(id)))
            }
        }
    }, [dispatch, id, selectedWarehouse])

    if (!id) {
        navigate(routes.WAREHOUSE_LIST)
        return null
    }

    return (
        <PageLayout>
            <PageHeader caption="Management / Warehouses / Details"/>
            <LayoutContainer className="layout-container-pad0">
                <div style={{ padding: '0px 8px 8px' }}>
                    {/* Row 1 */}
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={12}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <Select
                                    value={cropId === 0 ? '' : cropId}
                                    onChange={handleChangeCrop}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'select' }}
                                    // disableUnderline
                                >
                                    <MenuItem value="">
                                        <em>Crop</em>
                                    </MenuItem>
                                    {selectedWarehouse?.stock?.map(option => {
                                        return (
                                            <MenuItem key={option?.id} value={option?.id}>
                                                {option?.crop}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* Row 2 */}
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={6}>
                            <ListItemText primary={'From:'} />
                            <ListItem style={{ backgroundColor: '#f4f4f4' }}>
                                <TextField
                                    id="startDate"
                                    size="small"
                                    type="date"
                                    defaultValue={startDate}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        style: {
                                            // fontSize: 20,
                                            padding: 5,
                                        },
                                        // disableUnderline: true
                                    }}
                                    onChange={handleChangeDate}
                                />
                            </ListItem>
                        </Grid>

                        <Grid item xs={6}>
                            <ListItemText primary={'To:'} />
                            <ListItem style={{ backgroundColor: '#f4f4f4' }}>
                                <TextField
                                    id="endDate"
                                    size="small"
                                    type="date"
                                    defaultValue={endDate}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        style: {
                                            // fontSize: 20,
                                            padding: 5,
                                        },
                                        // disableUnderline: true
                                    }}
                                    onChange={handleChangeDate}
                                />
                            </ListItem>
                        </Grid>
                    </Grid>
                </div>

                {/* Total stock */}
                <ListItem style={{ backgroundColor: '#6aa84f' }}>
                    <ListItemText primary={'Total stock'} style={{ color: '#FFFFFF' }} />
                </ListItem>
                {selectedWarehouse?.stock?.map((item, index) => {
                    return (
                        <List key={index}>
                            <ListItem component="div">
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={item.crop} />
                                </ListItem>
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={`${item.weight} ${item.weight_unit || 'kg'}`} style={{ textAlign: 'right' }} />
                                </ListItem>
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={`${item.amount} ${item.amount_unit || 'pcs'}`} style={{ textAlign: 'right' }} />
                                </ListItem>
                                <ListItemSecondaryAction onClick={() => {handleWarehouseCreateTransaction(id, item.id)}}>
                                    <IconButton edge="end" aria-label="edit" >
                                        <Cached style={{color: '#2b78e4'}} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                })}

                {/* Last updates */}
                <ListItem style={{ backgroundColor: '#6aa84f' }}>
                    <ListItemText primary={'Last updates'} style={{ color: '#FFFFFF' }} />
                </ListItem>
                {filterTransactions(startDate, endDate, transactions).map((data, i) => {
                    return (
                        <List key={i} style={{ background: i % 2 ? '#f4f4f4' : '#FFFFFF' }}>
                            <ListItem onClick={() => { }}>
                                <ListItemIcon>
                                    <SvgIcon component={statusIcons[data.direction]} style={{ color: data.direction === 'in'? '#4caf50' : '#f44336' }} />
                                </ListItemIcon>
                                <ListItemText primary={data.weight + ' ' + data.weight_unit} secondary={warehouseStock?.crop || 'crop null'} />
                                <ListItemText primary={data.amount + ' ' + data.amount_unit} />

                                <ListItemSecondaryAction>
                                    <ListItemText primary={(data.updated).substr(0, 10)} secondary={data.user_name} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                })}
            </LayoutContainer>
        </PageLayout>
    )
}

export default WarehouseDetails