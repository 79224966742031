import React, {useState} from 'react'
import {Button, FormControl, Grid, MenuItem, Select} from '@mui/material';
import PageLayout from '../PageLayout/PageLayout'
import {PageHeader} from "../PageHeader/PageHeader";
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import FormInput from '../../atoms/FormInput';
import {Save} from '@mui/icons-material';
import {getApiUrl} from "../../../api/axios/api";
import {useSelector} from "react-redux";
import {IRootState} from "../../../types/IRootState";
import IAdminState from "../../../types/IAdminState";

const ReportsExport = () => {
    const {farms, zones} = useSelector<IRootState>(state => state.admin) as IAdminState

    // Date
    const [startDate, setStartDate] = useState<string>('2021-10-01')
    const [endDate, setEndDate] = useState<string>(new Date().toISOString().substring(0, 10))
    const [zoneId, setZoneId] = useState('0')
    const [farmId, setFarmId] = useState('0')

    const handleChangeFarms = (event) => {
        setFarmId(event.target.value)
    }
    const handleChangeZones = (event) => {
        setZoneId(event.target.value)
    }

    const handleChangeDate = (event) => {
        switch (event.target.id) {
            case 'startDate':
                setStartDate(event.target.value);
                break;

            case 'endDate':
                setEndDate(event.target.value);
                break;

            default:
                break;
        }
    };

    const exportExcel = () => {
        window.location.href = `${getApiUrl()}/reports/export-excel?farm_id=${farmId}&zone_id=${zoneId}&from=${startDate}&to=${endDate}`
    }

    return (
        <div>
            <PageLayout>
                {/* Header */}
                <PageHeader caption="Administration > Reports > Export Data"/>

                <LayoutContainer className="layout-container-pad0">
                    <div style={{padding: '0px 8px', paddingTop: '20px'}}>
                        {/* Row 1 */}
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={6}>
                                <div>Farm:</div>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Select
                                        value={farmId}
                                        onChange={handleChangeFarms}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'select' }}
                                        // disableUnderline
                                    >
                                        <MenuItem value="">
                                            <em>Farm</em>
                                        </MenuItem>
                                        {farms?.map(option => {
                                            return (
                                                <MenuItem key={option?.id} value={option?.id}>
                                                    {option?.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <div>Zone:</div>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <Select
                                        value={zoneId}
                                        onChange={handleChangeZones}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'select' }}
                                    >
                                        <MenuItem value="">
                                            <em>Zone</em>
                                        </MenuItem>
                                        {zones && zones.filter(zone => zone.farm_id === parseInt(farmId) || farmId === '').map(option => {
                                            return (
                                                <MenuItem key={option?.id} value={option?.id}>
                                                    {option?.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormInput
                                    id="startDate"
                                    label="From:"
                                    type="date"
                                    size={12}
                                    value={startDate}
                                    onChange={handleChangeDate}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormInput
                                    id="endDate"
                                    label="To:"
                                    type="date"
                                    size={12}
                                    value={endDate}
                                    onChange={handleChangeDate}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{paddingBottom: 8}}/>

                    <div style={{padding: '0px 8px'}}>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={6}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    style={{width: 150}}
                                    onClick={() => exportExcel()}
                                >
                                    <Save/> &nbsp; Download
                                </Button>
                            </Grid>
                        </Grid>
                    </div>

                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default ReportsExport