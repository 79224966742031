import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { Search } from "@mui/icons-material";

interface Props {
    placeholder?: string;
    onChange: (event: string) => void;
}

const SearchBar = (props: Props) => {
    const [value, setValue] = useState('');

    return (
        <TextField
            type="search"
            placeholder={props.placeholder || ''}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
                endAdornment: value.length === 0 &&
                    <InputAdornment position="end">
                        <Search style={{ top: 12, right: 10, position: "absolute", color: '#bdbdbd' }} />
                    </InputAdornment>
            }}
            onChange={(event) => { props.onChange(event.target.value); setValue(event.target.value) }}
        />
    )
}

export default SearchBar