import React from 'react';
import {Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import {PageHeader} from "./PageHeader/PageHeader";
import PageLayout from "./PageLayout/PageLayout";
import {ArrowRight} from "../atoms/ArrowRight";
import SearchBar from '../atoms/SearchBar';
import {IRootState} from "../../types/IRootState";
import IUserState from "../../types/IUserState";
import routes from '../../config/routes';

const Users = () => {
    const navigate = useNavigate()
    const {users} = useSelector<IRootState>(state => state.user) as IUserState

    const handleUserDetails = (id) => {
        navigate(routes.ADMIN_USERS_DETAILS.replace(':id', id))
    }

    return (
        <PageLayout>
            <PageHeader caption="Management > Users" />
            <LayoutContainer>
                {/* SearchBar */}
                <Grid container justifyContent="flex-end">
                    <Grid item xs={6}>
                        <SearchBar placeholder='Find Users...' onChange={(data) => { }} />
                    </Grid>
                </Grid>

                <Divider style={{ marginTop: 8 }} />

                {/* Data */}
                {users?.map((item, index) => {
                    return (
                        <List disablePadding key={index}>
                            <ListItem disableGutters component="div">
                                <ListItemText primary={item.name} secondary={item.email} />
                            </ListItem>
                            <ListItemSecondaryAction onClick={() => { handleUserDetails(item.id) }}>
                                <IconButton edge="end" aria-label="edit" >
                                    <ArrowRight />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </List>
                    )
                })}

                {/* Add User */}
                <Box display="flex" justifyContent="flex-end">
                    <Button size="small" variant="contained" style={{ width: 150 }} onClick={() => { navigate(routes.ADMIN_USERS_ADD) }}>
                        Add User
                    </Button>
                </Box>
            </LayoutContainer>
        </PageLayout>
    )
}

export default Users