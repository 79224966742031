// import {createTheme} from "@material-ui/core/styles";
import {createTheme} from '@mui/material/styles';

export const globalTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Hide arrows from input number
                    '& input[type=number]': {
                        MozAppearance: 'textfield'
                    },
                    '& input[type=number]::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0
                    },
                    '& input[type=number]::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0
                    },
                    // Remove autofill background shadow
                    '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 1000px white inset'
                    },
                    // TextField styles
                    '& label': {
                        color: ''
                    },
                    '& label.Mui-focused': {
                        color: ''
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: ''
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: ''
                        },
                        '&:hover fieldset': {
                            borderColor: '',
                            borderWidth: ''
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: ''
                        }
                    }
                }
            },
            variants: [
                {
                    props: { variant: 'outlined', size: 'small' },
                    style: {
                        input: {
                            paddingTop: 13
                        }
                    }
                }
            ]
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&:focus": {
                        backgroundColor: "white",
                    },
                    height: 14,
                    paddingTop: 16
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: 48,
                    paddingTop: 4,
                    paddingBottom: 4,
                    boxSizing: 'border-box',
                    width: 'auto',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }
            }
        }
    }
})