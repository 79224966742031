import {actionIds, BaseAction} from "../common";
import IAdminState from "../../types/IAdminState";

const defaultState: IAdminState = {
    farms: [],
    zones: [],
    selectedFarm: '',
    selectedZone: '',
    lot_data: [],
    lot_data_id: '',
    zone: {},
    statuses: [],
    lots: []
}

const adminReducer = (state: IAdminState = defaultState, action: BaseAction): IAdminState => {
    switch (action.type) {
        case actionIds.SET_FARMS_LIST:
            return {
                ...state,
                farms: action.payload.farms
            }

        case actionIds.SET_FARMS_DETAILS:
            const index = state.farms.findIndex(farm => farm.id === action.payload.farm.id)
            const newArray = [...state.farms]
            newArray[index].zones = action.payload.zones

            return { 
                ...state, 
                farms: newArray
            }

        case actionIds.SET_SELECTED_FARM:
            return { 
                ...state, 
                selectedFarm: action.payload.selectedFarm,
            }
        
        case actionIds.SET_ZONE_LIST:
            return { 
                ...state, 
                zones: action.payload.zones,
            }
        
        case actionIds.SET_SELECTED_ZONE:
            return { 
                ...state, 
                selectedZone: action.payload.selectedZone,
            }

        case actionIds.SET_ZONES_DETAILS:
            // convert rows value to integer
            action.payload.lots?.forEach((obj) => {
                obj.row = parseInt(obj.row , 10);
            });

            return { 
                ...state, 
                zone: action.payload.zone,
                statuses: action.payload.statuses ?? [],
                lots: action.payload.lots ?? []
            }

        case actionIds.SET_REPORTS_ACTIVITY:
            return { 
                ...state, 
                lot_data: action.payload.lot_data
            }

        case actionIds.SET_ADMIN_DATA:
            return { 
                ...state, 
                ...action.payload.data,
            }
    
        // case actionIds.LOADING_UNSET:
        //     return {
        //         ...defaultState,
        //     }

        default:
            return state
    }
}

export default adminReducer;