import {actionIds, BaseAction} from "../common";
// import IAdminState from "../../types/IAdminState";

const setReportsActivityAction: (data: any) =>
    BaseAction = (data) => ({
    type: actionIds.SET_REPORTS_ACTIVITY,
    payload: {
        ...data
    },
});

export default setReportsActivityAction;
