import React from 'react';
import {AnyAction, CombinedState} from 'redux';
import {configureStore} from '@reduxjs/toolkit'
import RouterWrapper from "./RouterWrapper";
import {Provider} from "react-redux";
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import createSagaMiddleware from 'redux-saga';
import {createBrowserHistory} from 'history';
import {PersistGate} from 'redux-persist/integration/react';
import rootReducer from "./state/reducers";
// import setupSocket, {IWebSocket} from "./api/sockets";
import rootSaga from "./state/sagas";
import {IRootState} from "./types/IRootState";
import getConfigAction from "./state/actions/getConfig";
import {APP_VERSION} from './config/version';
import {ThemeProvider} from '@mui/material/styles';
import {globalTheme} from './config/muiTheme';
// import {gtmPageView} from "./lib/gtm";

const persistConfig = {
    key: 'harvesting-app',
    storage,
    stateReconciler: hardSet,
    // blacklist: ['admin']
}

const persistedReducer = persistReducer<CombinedState<IRootState>, AnyAction>(persistConfig, rootReducer)

// Browser History for use with react router & GTM events
const history = createBrowserHistory()
// gtmPageView(window.location.pathname)
// history.listen((location, action) => {
//     window.scrollTo(0, 0)
//     // gtmPageView(location.pathname)
// })

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(sagaMiddleware),
	devTools: APP_VERSION.includes('DEV')
})
const persistor = persistStore(store, null, () => {
    // Add initial actions
    store.dispatch(getConfigAction())
})
// const socket: IWebSocket = setupSocket(store.dispatch)
sagaMiddleware.run(rootSaga, {history})

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ThemeProvider theme={globalTheme}>
                    <RouterWrapper history={history} />
                </ThemeProvider>
            </PersistGate>
        </Provider>
    )
}

export default App