import React from 'react';
import './Footer.scss';
import classNames from 'classnames';
import {AppBar, Badge, BottomNavigation, BottomNavigationAction, Toolbar} from '@mui/material';
import {Person, QrCode2, Settings} from '@mui/icons-material';

import {useNavigate, useLocation} from 'react-router-dom';
import useKeyboard from '../../../hooks/useKeyboard';
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import {hasPermission} from "../../atoms/HasPermission/HasPermission";
import {useSelector} from "react-redux";
import {IRootState} from "../../../types/IRootState";
import IUserState from "../../../types/IUserState";
import {ISyncState} from '../../../types/ISyncState';
import routes from '../../../config/routes';

interface Props {
    className?: string;
}

const Footer = (props: Props) => {
    const {keyboardShown} = useKeyboard();
    const navigate = useNavigate();
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);

    // const routeMatch = useRouteMatch(['/scan-qr', '/update-status', '/profile', '/administration', '/dev']); //v5
    const {roles} = useSelector<IRootState>(state => state.user) as IUserState
    const {unsyncedItems} = useSelector<IRootState>(state => state.sync) as ISyncState

    const currentTab = '/' + pathnames[0]

    const tabNameToIndex = {
        0: routes.SCAN_QR_PAGE,
        1: routes.PROFILE,
        2: routes.ADMIN_PAGE
    };

    const indexToTabName = {
        [routes.SCAN_QR_PAGE]: 0,
        [routes.UPDATE_STATUS_PAGE]: 0,
        [routes.UPDATE_STATUS_PAGE_2]: 0,
        [routes.UPDATE_STATUS_PAGE_3]: 0,
        [routes.PROFILE]: 1,
        [routes.ADMIN_PAGE]: 2
    };

    const [selectedTab, setSelectedTab] = React.useState<number>(indexToTabName[currentTab]);

    const handleChangeTap = (event, newValue) => {
        // Stop user navigate to the same tab multiple times
        // Compare currentTab and new selectedTab value
        if (selectedTab !== newValue) {
            navigate(tabNameToIndex[newValue]);
            setSelectedTab(newValue);
        } else {
            navigate(tabNameToIndex[newValue], {replace: true});
            setSelectedTab(newValue);
        }
    };

    return (
        <div className={classNames('footer', props.className)}>
            <LayoutContainer>
                {/* Padding */}
                <Toolbar/>
                {/*  */}
                {!keyboardShown && <AppBar position="fixed" style={{background: '#ffffff', top: 'auto', bottom: 0}}>
                    <BottomNavigation
                        value={selectedTab}
                        onChange={handleChangeTap}
                        showLabels
                        // className={classes.root}
                    >
                        <BottomNavigationAction label="Harvest" icon={<QrCode2/>}/>
                        <BottomNavigationAction label="Profile" icon={<>
                            {unsyncedItems > 0 && <Badge badgeContent={unsyncedItems} color="error">
                                <Person/>
                            </Badge>}
                            {unsyncedItems === 0 && <Person/>}
                        </>}/>
                        {hasPermission('admin.index', roles) && <BottomNavigationAction label="Manage" icon={<Settings/>}/>}
                    </BottomNavigation>
                </AppBar>}
            </LayoutContainer>
        </div>
    )
}

export default Footer