import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Grid, IconButton, ListItem, ListItemIcon, ListItemText, SvgIcon} from '@mui/material';
import {Create, QrCode2, Room} from "@mui/icons-material";
import LabelledOutline from '../atoms/LabelledOutline/LabelledOutline';
import {isAndroid, isDesktop, isIOS,} from "react-device-detect";
// import {Chart, PieSeries} from '@devexpress/dx-react-chart-material-ui';
// import {Animation, Palette} from '@devexpress/dx-react-chart';
import {TransformComponent, TransformWrapper} from "@pronestor/react-zoom-pan-pinch";
import {IRootState} from '../../types/IRootState';
import IAdminState from '../../types/IAdminState';
// import Breadcrumb from '../molecules/Breadcrumb';
import PageLayout from "./PageLayout/PageLayout";
import {PageHeader} from "./PageHeader/PageHeader";
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import statusIcons from '../../config/statusIcons';
import clipboard from '../../assets/clipboard.svg';
//
import PieChart, {
    Legend,
    Series,
    Tooltip,
    Format,
    Label,
    Connector,
    Export
} from 'devextreme-react/pie-chart';

const Details = () => {
    const hiddenLink = useRef<any>(null);
    const [href, setHref] = useState('')
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [scale, setScale] = useState<number>(1);

    const { zones, selectedZone, statuses, lots } = useSelector<IRootState>(state => state.admin) as IAdminState
    let zone = zones.find((zone: any) => zone.id === selectedZone)

    let colorPalettes = statuses?.map(data => data.color).concat()

    // Define rect width height, and spaces between them
    const rectW = 6
    const rectH = 6
    const spacing = 2

    const draw2 = React.useCallback(() => {
        const canvas = canvasRef.current
        const ctx = canvas?.getContext('2d')

        if (ctx) {
            lots.forEach((lot) => {
                const { row, column } = lot
                const { color }: any = statuses.find((status) => status.name === lot.status)

                ctx.fillStyle = color;
                ctx.fillRect(row * (rectW + spacing) - rectW, column * (rectH + spacing) - rectH, rectW, rectH);
            })
        }
    }, [lots, statuses])

    useEffect(() => {
        const latitude = '52.3645';
        const longitude = '13.5109';

        if (isDesktop) {
            setHref("https://maps.google.com?q=" + latitude + "," + longitude)
        } else if (isAndroid) {
            setHref(`geo://?q=${latitude},${longitude}`)
        } else if (isIOS) {
            setHref(`maps://app?q=@${latitude}, ${longitude}`)
        }
    }, [])

    useEffect(() => {
        // Rerender canvas
        draw2()
    }, [draw2])

    const handleClickCanvas = (e) => {
        const canvas = canvasRef.current
        let offset = canvas?.getBoundingClientRect()

        if (offset) {
            let pageX = e.pageX - offset.left;
            let pageY = e.pageY - window.pageYOffset - offset.top;

            let xPos = Math.ceil(pageX / (rectW + spacing) / scale);
            let yPos = Math.ceil(pageY / (rectH + spacing) / scale);

            let selectedLot = lots.find(lot => lot.row === xPos && lot.column === yPos)
            console.log("selectedLot", selectedLot)
        }
    }

    // const draw = () => {
    //     const ctx = canvasRef.current?.getContext('2d')
    //     let maxRows = Math.max(...lots.map(lot => lot.row))
    //     let maxCloumns = Math.max(...lots.map(lot => lot.column))

    //     if (ctx) {
    //         var i = 0;
    //         for (var y = 0; y < maxCloumns; y++) {
    //             for (var x = 0; x < maxRows; x++) {
    //                 ctx.beginPath();
    //                 switch (lots[i].status) {
    //                     case 'Planted':
    //                         ctx.fillStyle = "#009E0F";
    //                         break;
    //                     case 'Harvested':
    //                         ctx.fillStyle = "#2B78E4";
    //                         break;
    //                     case 'Unhealthy':
    //                         ctx.fillStyle = "#CF2A27";
    //                         break;
    //                     case 'Recovered':
    //                         ctx.fillStyle = "#009E0F";
    //                         break;
    //                     case 'Wrapped':
    //                         ctx.fillStyle = "#00FFFF";
    //                         break;
    //                     case 'Skipped':
    //                         ctx.fillStyle = "#999999";
    //                         break;
    //                     default:
    //                         break;
    //                 }

    //                 ctx.fillRect(8 * x + 4, 8 * y + 4, 6, 6);

    //                 // Test Square
    //                 // ctx.strokeStyle = "#2b78e4";
    //                 // ctx.rect(30 * x + 4, 30 * y + 4, 20, 20);
    //                 // ctx.stroke();
    //                 i++;
    //             }
    //         }
    //     }
    // }

    const handleLocation = () => {
        hiddenLink.current.click();
    }

    return (
        <div className="">
            <PageLayout>
                {/* <Breadcrumb /> */}
                <PageHeader caption={`Administration > Farms > Zones > ${zone?.name}`} />
                <LayoutContainer>
                    <LabelledOutline label="Details">
                        {/* Farm Name */}
                        <Grid container>
                            <Grid item xs={3}>
                                <ListItem>
                                    <ListItemText primary="Name:" />
                                </ListItem>
                            </Grid>
                            <Grid item>
                                <ListItem>
                                    <ListItemText primary={zone?.name} />
                                </ListItem>

                            </Grid>
                            <Grid item xs>
                                <div style={{ textAlign: 'right' }}>
                                    <ListItemIcon>
                                        <IconButton edge="end" aria-label="edit" style={{ backgroundColor: 'transparent' }} >
                                            <Create />
                                        </IconButton>
                                    </ListItemIcon>
                                </div>
                            </Grid>
                        </Grid>

                        {/* Farm Lot */}
                        <Grid container>
                            <Grid item xs={3}>
                                <ListItem>
                                    <ListItemText primary="Lots:" />
                                </ListItem>
                            </Grid>
                            <Grid item xs>
                                <ListItem>
                                    <ListItemText primary={`${zone?.lots_total} Lots`} />
                                </ListItem>

                            </Grid>
                            <Grid item xs>
                                <div style={{ textAlign: 'right' }}>
                                    <ListItemIcon>
                                        <IconButton edge="end" aria-label="qr-code" style={{ backgroundColor: 'transparent' }} >
                                            <QrCode2 />
                                        </IconButton>
                                    </ListItemIcon>
                                </div>
                            </Grid>
                        </Grid>

                        {/* Farm Address */}
                        <Grid container>
                            <Grid item xs={3}>
                                <ListItem>
                                    <ListItemText primary="Address:" />
                                </ListItem>
                            </Grid>
                            <Grid item >
                                <ListItem>
                                    <ListItemText primary="Brandenburg" />
                                </ListItem>

                            </Grid>
                            <Grid item xs>
                                <div style={{ textAlign: 'right' }}>
                                    <ListItemIcon onClick={() => handleLocation()}>
                                        <IconButton edge="end" aria-label="location" style={{ backgroundColor: 'transparent' }} >
                                            <Room />
                                            <a ref={hiddenLink} href={href} rel="noreferrer" target="_blank"> </a>
                                        </IconButton>
                                    </ListItemIcon>
                                </div>
                            </Grid>
                        </Grid>
                    </LabelledOutline>

                    <div style={{ marginTop: 15 }} />

                    {/* Overview v2 */}
                    <ListItem style={{ backgroundColor: '#6aa84f' }}>
                        <ListItemText primary={'Overview'} style={{ color: '#FFFFFF' }} />
                    </ListItem>

                    {/* Doughnut Chart*/}
                    {statuses.length > 0 && <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: 250
                    }}>
                        {/* dev express */}
                        {/* <Chart
                            data={statuses}
                            width={250}
                            height={250}
                        >
                            <Palette scheme={colorPalettes} />

                            <PieSeries
                                valueField="count"
                                argumentField="name"
                                innerRadius={0.6}
                            />
                            <Animation />
                        </Chart> */}

                        {/* dev extreme */}
                        <PieChart
                            id="pie"
                            type="doughnut"
                            title="Crop Details"
                            palette={colorPalettes}
                            dataSource={statuses}
                            style={{
                                width: '100%'
                            }}
                        >
                            <Series valueField="count" argumentField="name">
                                <Label visible={false}>
                                    <Connector visible={true} />
                                </Label>
                            </Series>
                            <Export enabled={false} />
                            <Legend
                                visible={false}
                                margin={0}
                                horizontalAlignment="right"
                                verticalAlignment="top"
                            />
                            <Tooltip enabled={true} >
                                <Format />
                            </Tooltip>
                        </PieChart>
                    </div>}

                    {/* Fallback Content */}
                    {statuses.length === 0 && <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: 250,
                        position: 'relative'
                    }}>
                        <embed style={{
                            marginTop: 20,
                            display: 'block',
                            maxWidth: '60%',
                            maxHeight: '60%',
                        }} type='image/svg+xml' src={clipboard} />

                        <ListItemText style={{
                            position: 'absolute',
                            bottom: 30,
                            left: 0,
                            right: 0,
                            margin: 'auto',
                            textAlign: 'center'
                        }}
                            primary={'Information not available'}
                        />
                    </div>}

                    {/* Statuses */}
                    {statuses.length > 0 && statuses.map((data, i) => {
                        return (
                            <ListItem disableGutters key={i}>
                                <SvgIcon component={statusIcons[data.icon]} style={{ color: data.color }} />
                                <ListItemText primary={data.name} style={{ color: data.color }} />

                                <div style={{ textAlign: 'right' }}>
                                    <ListItemText primary={`${data.count} Lots`} />
                                </div>
                            </ListItem>
                        )
                    })}

                    {/* Individual Lots */}
                    <ListItem style={{ backgroundColor: '#6aa84f' }}>
                        <ListItemText primary={'Individual Lots'} style={{ color: '#FFFFFF' }} />
                    </ListItem>

                    <TransformWrapper onZoomStop={(ref) => {
                        setScale(ref.state.scale)
                    }}>
                        <TransformComponent>
                            <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                                <canvas
                                    ref={canvasRef}
                                    // width={800}
                                    // height={500}
                                    onClick={handleClickCanvas}
                                    style={{ imageRendering: 'pixelated' }} // remove blur
                                />
                            </div>
                        </TransformComponent>
                    </TransformWrapper>

                    {/* Canvas v2 */}
                    {/* <LotOverview data={lots} onClick={(e) => console.log('LotOverview Clicked', e)} /> */}
                    {/*TBD*/}
                </LayoutContainer>
            </PageLayout>
        </div>
    );
}

export default Details;
