import {actionIds, BaseAction} from "../common";
import IHarvestState from "../../types/IHarvestState";

const setLotDetailsAction: (data: IHarvestState) =>
    BaseAction = (data) => ({
    type: actionIds.SET_LOT_DETAILS,
    payload: {...data},
});

export default setLotDetailsAction;
