import {actionIds, BaseAction} from "../common";
import IUserState from "../../types/IUserState";

const defaultState: IUserState = {
    login: '',
    password: '',
    user: null,
    roles: [],
    gpsPosition: {
        latitude: 0,
        longitude: 0,
        accuracy: 0,
        altitude: 0,
        altitudeAccuracy: 0,
        heading: 0,
        speed: 0
    },
    users: []
}

const userReducer = (state: IUserState = defaultState, action: BaseAction): IUserState => {
    switch (action.type) {
        case actionIds.SET_LOGIN_DATA:
            return {
                ...state,
                ...action.payload.data
            }
        
        case actionIds.UNSET_LOGIN_DATA:
            return {
                ...state,
                login: '',
                password: ''
            }
        
        case actionIds.SET_USER:
            return {
                ...state,
                user: action.payload,
                roles: action.payload.roles
            }

        case actionIds.SET_GPS_POSITION:
            return {
                ...state,
                gpsPosition: action.payload.gpsPosition
            }

        case actionIds.SET_USERS_LIST:
            return {
                ...state,
                users: action.payload.users
            }

        case actionIds.RESET_STORE:
            return {
                ...defaultState,
            }

        default:
            return state
    }
}

export default userReducer;
