import React, {Suspense} from 'react';
import LoginPage from "./components/pages/LoginPage";
import AdminPage from './components/pages/AdminPage'
import ZoneDetails from './components/organisms/ZoneDetails';
import Farms from './components/organisms/Farms';
import AddFarms from './components/organisms/AddFarms';
import Zones from './components/organisms/Zones';
import routes from "./config/routes";
import {BrowserRouter as Router, Navigate, Route, Routes, Outlet, useLocation} from 'react-router-dom';
import Spinner from "./components/atoms/Spinner/Spinner";
import {IRootState} from "./types/IRootState";
import {useSelector} from "react-redux";
// import Footer2 from "./components/molecules/Footer/Footer2"
// import HomePage from "./components/pages/HomePage";
import UpdateStatusPage1 from "./components/pages/UpdateStatusPage1";
import UpdateStatusPage2 from "./components/pages/UpdateStatusPage2";
import UpdateStatusPage3 from "./components/pages/UpdateStatusPage3";
import ScanQrPage from "./components/pages/ScanQrPage";
import ProfilePage from "./components/pages/ProfilePage";
import Reports from './components/organisms/Reports/Reports';
import ReportList from './components/organisms/Reports/ReportList';
import WorkerOverview from './components/organisms/Reports/WorkerOverview';
import ReportsDetails from './components/organisms/Reports/ReportsDetails';
import ProfileSyncQueuePage from "./components/pages/ProfileSyncQueuePage";
import WarehouseList from "./components/organisms/Warehouse/WarehouseList";
import WarehouseDetails from "./components/organisms/Warehouse/WarehouseDetails";
import Users from './components/organisms/Users';
import AddUsers from './components/organisms/AddUsers';
import UsersDetails from './components/organisms/UsersDetails';
import DebugPage from "./components/pages/DebugPage";
import DebugErrorLogs from './components/organisms/Debug/DebugErrorLogs';
import DebugScannedData from './components/organisms/Debug/DebugScannedData';
import DebugLogDataHistory from './components/organisms/Debug/DebugLogDataHistory';
import WarehouseCreateTransaction from "./components/organisms/Warehouse/WarehouseCreateTransaction";
import ReportsExport from "./components/organisms/Reports/ReportsExport";
import Notifications from "./components/molecules/Notifications/Notifications";
import ForgotPasswordPage from './components/pages/ForgotPasswordPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage';

interface Props {
    history: any;
}

const RouterWrapper = (props: Props) => {
    const loading = useSelector<IRootState>(state => state.loader?.loading) as boolean
    let auth = useSelector<IRootState>(state => state.user.user?.success) as boolean
    // const {history} = props;

    const RequireAuth = () => {
        const location = useLocation()
        return (
            auth ? <Outlet /> : <Navigate to={'/'} state={{from: location}} replace/> 
        )
    }

    return (
        <Router>
            <Notifications/>
            <Suspense fallback={<Spinner/>}>
                {loading && (<Spinner/>)}
                    <Routes>
                        <Route path="/" element={<LoginPage />} />
                        <Route path={routes.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
                        <Route path={routes.RESET_PASSWORD} element={<ResetPasswordPage />} />
                        <Route element={<RequireAuth />}>
                            <Route path={routes.SCAN_QR_PAGE} element={<ScanQrPage />} />
                            <Route path={routes.UPDATE_STATUS_PAGE} element={<UpdateStatusPage1 />} />
                            <Route path={routes.UPDATE_STATUS_PAGE_2} element={<UpdateStatusPage2 />} />
                            <Route path={routes.UPDATE_STATUS_PAGE_3} element={<UpdateStatusPage3 />} />
                            <Route path={routes.DEBUG} element={<DebugPage />} />
                            <Route path={routes.DEBUG_ERROR_LOGS} element={<DebugErrorLogs />} />
                            <Route path={routes.DEBUG_SCANNED_DATA} element={<DebugScannedData />} />
                            <Route path={routes.DEBUG_LOG_DATA_HISTORY} element={<DebugLogDataHistory />} />
                            <Route path={routes.PROFILE} element={<ProfilePage />} />
                            <Route path={routes.PROFILE_SYNC_QUEUE} element={<ProfileSyncQueuePage />} />
                            <Route path={routes.ADMIN_PAGE} element={<AdminPage />} />
                            <Route path={routes.ADMIN_USERS} element={<Users/>} />
                            <Route path={routes.ADMIN_USERS_ADD} element={<AddUsers />} />
                            <Route path={routes.ADMIN_USERS_DETAILS} element={<UsersDetails />} />
                            <Route path={routes.ADMIN_FARMS} element={<Farms />} />
                            <Route path={routes.ADMIN_FARMS_ZONES} element={<Zones />} />
                            <Route path={routes.ADMIN_FARMS_ZONES_ADD} element={<AddFarms />} />
                            <Route path={routes.ADMIN_FARMS_ZONES_DETAILS} element={<ZoneDetails />} />
                            <Route path={routes.REPORT_LIST} element={<ReportList />} />
                            <Route path={routes.REPORT_OVERVIEW} element={<WorkerOverview />} />
                            <Route path={routes.REPORT_ACTIVITY} element={<Reports />} />
                            <Route path={routes.REPORT_ACTIVITY_DETAILS} element={<ReportsDetails />} />
                            <Route path={routes.REPORT_EXPORT} element={<ReportsExport />} />
                            <Route path={routes.WAREHOUSE_LIST} element={<WarehouseList />} />
                            {/* <Route path={routes.WAREHOUSE_EDIT} component={WarehouseEdit} /> */}
                            <Route path={routes.WAREHOUSE_CREATE_TRANSACTION} element={<WarehouseCreateTransaction />} />
                            <Route path={routes.WAREHOUSE_DETAILS} element={<WarehouseDetails />} />
                        </Route>
                    </Routes>
                {/* <SessionTimeout /> */}
            </Suspense>
        </Router>
    )
};

export default RouterWrapper;