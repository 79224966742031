import {actionIds, BaseAction} from "../common";
import IAdminState from "../../types/IAdminState";

const setFarmsDetailsAction: (data: IAdminState) =>
    BaseAction = (data) => ({
    type: actionIds.SET_FARMS_DETAILS,
    payload: {
        ...data
    },
});

export default setFarmsDetailsAction;
