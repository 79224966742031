import {actionIds, BaseAction} from "../common";

const setZonesListAction: (data: any) =>
    BaseAction = (data) => ({
    type: actionIds.SET_ZONE_LIST,
    payload: {
        ...data
    },
});

export default setZonesListAction;
