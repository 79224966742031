import React, {ReactNode} from 'react';
import {useSelector} from "react-redux";
import {IRootState} from "../../../types/IRootState";
import IUserState from "../../../types/IUserState";

interface Props {
    permission: string;
    children: ReactNode;
}

const rolesPermissions = {
    admin: ['admin.index'],
    manager: ['admin.index'],
    user: [],
    guest: []
}

const HasPermission = (props: Props) => {
    const {roles} = useSelector<IRootState>(state => state.user) as IUserState
    if (!roles) {
        return null
    }

    if (!hasPermission(props.permission, roles)) {
        return null
    }

    return (
        <>{props.children}</>
    )
}

export const hasPermission = (permission: string, roles: undefined | string[]): boolean => {
    // TODO: fetch permissions from DB for given roles and cache them
    return !!roles && roles.filter(role => {
        return rolesPermissions[role].includes(permission)
    }).length > 0
}

export default HasPermission