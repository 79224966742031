import axios from "axios";
import {IDebugReport} from "../../types/IDebugReport";
import {saveErrorLog, saveLogDataHistory} from "../../config/db";

const hostUrl = getHostUrl()
const url = `${hostUrl}/api/v1`

export const getApiUrl = () => {
    return url
}

export const login = async (data) => {

    // return await axios.post(`https://reqres.in/api/login`, data3)
    //     .then(data => ({ data }))
    //     .catch(error => ({ error }))

    return await axios.post(`${url}/users/login`, data, {withCredentials: true})
        .then((response) => {
            return response
        })
        .catch((error) => {
            saveErrorLog(`${url}/users/login`, error.response.data, data)
            return error.response
        })
};

export const createUser = async (data) => {
    return await axios.post(`${url}/users/create`, data, {withCredentials: true});
};

export const getUsersList = async () => {
    return await axios.get(`${url}/users/list`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const harvest = async (data) => {
    data.weight = parseFloat(data.weight)
    data.amount = parseFloat(data.amount)
    await saveLogDataHistory(data.lot_id, data.status_id, data.amount, data.weight, data.notes, data.imageData)
    return await axios.post(`${url}/lots/harvest`, data, {withCredentials: true})
        .then((response) => {
            return response
        })
        .catch(async (error) =>  {
            saveErrorLog(`${url}/lots/harvest`, error.message, data)
            if (error.message === 'Network Error') {
                return { status: 'Network Error' }
            }
            return error.response
        })
};

export const getOnlineStatus = async () => {
    let config = {
        headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
        },
        withCredentials: true 
    }
    return await axios.get(hostUrl, config)
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return { online: true }
            }
        })
        .catch((error) => {
            return { online: false }
        })
};

export const logout = async () => {
    return await axios.get(`${url}/users/logout`, {withCredentials: true})
        .then(response => ({ data: response.data }))
        .catch(error => ({ error: error.response.data }))
};

export const debugReport = async (data: IDebugReport) => {
    return await axios.post(`${url}/debug/report`, data, {withCredentials: true})
        .then(response => (response.data))
        .catch((error) => {
            throw new Error(error)
        })
};

export const getLot = async (id) => {
    return await axios.get(`${url}/lots/details/${id}`, {withCredentials: true})
    .then((response) => { return response })
    .catch((error) => { return error })
};

export const getCrops = async () => {
    return await axios.get(`${url}/crops/list`, {withCredentials: true})
    .then((response) => { return response })
    .catch((error) => { return error })
};

export const getFarmsList = async () => {
    return await axios.get(`${url}/farms/list`, {withCredentials: true})
        .then(response => ({ data: response.data }))
        .catch(error => ({ error: error.response.data }))
};

export const getFarmsDetails = async (id) => {
    return await axios.get(`${url}/farms/details/${id}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getZoneCropStatusList = async (id) => {
    return await axios.get(`${url}/zones/crop-status-list/${id}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getReportsActivity = async ({farm_id, zone_id, user_id, crop_status_id, startDate, endDateOffset, offset, limit}) => {
    return await axios.get(`${url}/reports/activity?farm_id=${farm_id}&zone_id=${zone_id}&user_id=${user_id}&crop_status_id=${crop_status_id}&from=${startDate}&to=${endDateOffset}&offset=${offset}&limit=${limit}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getReportsImageUrl = async ({farm_id, zone_id, user_id, crop_status_id, startDate, endDateOffset}) => {
    return await axios.get(`${url}/reports/activity?farm_id=${farm_id}&zone_id=${zone_id}&user_id=${user_id}&crop_status_id=${crop_status_id}&from=${startDate}&to=${endDateOffset}&limit=10000`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getReportsGraph = async ({farm_id, zone_id, user_id, crop_status_id, startDate, endDateOffset}) => {
    return await axios.get(`${url}/reports/activity/graph?farm_id=${farm_id}&zone_id=${zone_id}&user_id=${user_id}&crop_status_id=${crop_status_id}&from=${startDate}&to=${endDateOffset}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getWarehouseList = async () => {
    return await axios.get(`${url}/warehouse/list`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getWarehouseDetails = async (id: number) => {
    return await axios.get(`${url}/warehouse/details/${id}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getWarehouseTransactions = async (id: number) => {
    return await axios.get(`${url}/warehouse/transactions/${id}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const createWarehouseTransaction = async (data) => {
    let newData = {
        ...data,
        image_data: data.imageData
    }
    delete newData.imageData
    return await axios.post(`${hostUrl}/api/v1/warehouse/create-transaction`, newData, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => {
            saveErrorLog(`${url}/api/v1/warehouse/create-transaction`, error.response.data.error, data)
            return error.response 
        })
};

export const getZonesList = async () => {
    return await axios.get(`${url}/zones/list`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getZonesDetails = async (id) => {
    return await axios.get(`${url}/zones/details/${id}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export const getSyncLots = async (timestamp: number, offset: number) => {
    return await axios.get(`${url}/lots/sync-lots?ts=${timestamp}&offset=${offset}`, {withCredentials: true})
        .then((response) => { return response })
        .catch((error) => { return error })
};

export function getHostUrl() {
    const parsedUrl = new URL(window.location.href)
    console.log('getHostUrl', parsedUrl.origin)
    if(parsedUrl.origin.includes('127.0.0.1:3000')) {
        return parsedUrl.origin.replace(':3000', ':8080')
    } else if(parsedUrl.origin.includes('localhost') || parsedUrl.origin.match(/^https?:\/\/[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/)) {
        return 'https://harvest-stage.nimio.media'
    }
    return parsedUrl.origin
}