import React, {useEffect} from 'react';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    SvgIcon,
    Typography
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import routes from '../../../config/routes';
import statusIcons from '../../../config/statusIcons';
import {IRootState} from '../../../types/IRootState';
import IHarvestState from '../../../types/IHarvestState';
import {ICropStatus} from '../../../types/ICropsState';
import setHarvestDataAction from '../../../state/actions/setHarvestData';
import getLotDetailsAction from '../../../state/actions/getLotDetails';
import {ArrowRight} from "../../atoms/ArrowRight";
import {PageHeader} from "../PageHeader/PageHeader";
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';

const UpdateStatus1 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {lot, lot_id, zoneName, cropsPerLot} = useSelector<IRootState>(state => state.harvest) as IHarvestState
    const statuses = useSelector<IRootState>(state => state.crops.crops[0].statuses) as ICropStatus[]
    const status = statuses.find((status) => status.name === lot?.status)

    useEffect(() => {
        dispatch(getLotDetailsAction(lot_id))
    }, [dispatch, lot_id])
    
    // TODO: Generate cropsPerLot
    // console.log(Array.from({length: 3}, (_, i) => i +1));
    // expected output: Array [1, 2, 3]

    const crops = Array.from({length: parseInt(cropsPerLot ?? '0')}, (_, i) => i + 1)
        .map((index) => ({
            id: 0,
            index,
            status: lot?.status || 'Unknown',
            updated: lot?.updated_date || '0000-00-00 00:00:00',
            icon: status?.icon || 'Unknown',
            color: status?.color || '#999999'
        }))

    const handleSelectCrop = (lot_crop_id, index) => {
        dispatch(setHarvestDataAction({
            lot_crop_id: lot_crop_id,
            crop_index: index,
        }))
        navigate(routes.UPDATE_STATUS_PAGE_2)
    }

    return (
        <div>
            {/* Header */}
            <List disablePadding style={{background: '#f4f4f4'}}>
                <ListItem disableGutters>
                    <ListItemText
                        primary={<Typography variant="h5" align="center"
                                             style={{marginTop: -2, marginBottom: -5}}>{zoneName}</Typography>}
                        secondary={<Typography variant="body2" align="center">Zone</Typography>}
                        style={{textAlign: 'center'}}
                    />

                    <Divider orientation="vertical" flexItem/>

                    <ListItemText primary={lot?.name} style={{paddingLeft: 15}}/>
                </ListItem>
            </List>

            {/* Lot Action Status */}
            <PageHeader caption="Lot Actions > Update Status (1/3)"/>

            {/* Progress Bar */}
            <List style={{background: '#f4f4f4'}}>
                <ListItem>
                    <ListItemText primary="Please select a tree:"/>
                    <ListItemText primary={<ProgressBar variant="determinate" value={30}/>}/>
                </ListItem>
            </List>

            {/* Tree */}
            {crops?.map((data, i) => {
                return (
                    <List key={i} style={{background: i % 2 ? '#f4f4f4' : '#FFFFFF'}}>
                        <ListItem>
                            <ListItemText primary={`Tree #${data.index}`} secondary={(data.updated).substr(0, 10)}/>

                            <SvgIcon component={statusIcons[data.icon]} style={{color: data.color}}/> &nbsp;
                            <ListItemText primary={data.status} style={{color: data.color}}/>

                            <ListItemSecondaryAction onClick={() => handleSelectCrop(data.id, i + 1)}>
                                <IconButton edge="end" aria-label="edit">
                                    <ArrowRight/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                )
            })}
        </div>
    )
}

export default UpdateStatus1