export interface ParseQrcodeResult {
    version: string;
    lotId: string;
    zoneName: string;
    zoneId: string;
    row: string;
    column: string;
    cropId: string;
    cropsPerLot: string;
}

// QR codes contain more data now, so depending on whether it just contains a single value or more complex value,
// this method parses the data into a usable structure
export const parseQrcode = (url: string): ParseQrcodeResult | null => {
    const urlParts = url.split('/view/')
    if (urlParts.length === 2) {
        let returnObj = {
            version: '0.1',
            lotId: '0',
            zoneName: 'A',
            row: '',
            column: '',
            zoneId: '2',
            cropId: '1',
            cropsPerLot: '6',
        }

        const resultParts = urlParts[1].split(';')
        if (resultParts.length === 1) {
            return workaroundQr({
                ...returnObj, ...{
                    lotId: resultParts[0],
                }
            })
        } else if (resultParts[0] === 'v1') {
            return workaroundQr({
                ...returnObj, ...{
                    version: resultParts[0],
                    lotId: resultParts[1],
                    zoneId: resultParts[2],
                    cropId: resultParts[4],
                    cropsPerLot: '6',
                }
            })
        } else if (resultParts[0] === 'v2') {
            return workaroundQr({
                ...returnObj, ...{
                    version: resultParts[0],
                    lotId: resultParts[1],
                    zoneName: resultParts[2],
                    zoneId: resultParts[3],
                    cropId: resultParts[5],
                    cropsPerLot: resultParts[6],
                }
            })
        } else if (resultParts[0] === 'v3') {
            return workaroundQr({
                ...returnObj, ...{
                    version: resultParts[0],
                    lotId: resultParts[1],
                    zoneName: resultParts[2],
                    zoneId: resultParts[3],
                    cropId: resultParts[4],
                    cropsPerLot: resultParts[5],
                    row: resultParts[6],
                    column: resultParts[7],
                }
            })
        } else {
            alert('The parsed QR code appears to be invalid');
            console.error('Unknown version: ', resultParts[0])
        }
    }

    return null
}

// TODO: once the syncing lot data feature is complete, we only need to rely on QR code data if no data was found in cache
function workaroundQr(scanData: ParseQrcodeResult): ParseQrcodeResult {
    // Fix wrongly printed QR codes for Zone E and F in L2
    if (scanData.zoneId === '7' || scanData.zoneId === '8') {
        scanData.cropsPerLot = '35';
    }

    return scanData;
}