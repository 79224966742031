import {Add, ContentCut, DeleteForever, FastForward, Favorite, Help, LocalMall, LocalFlorist, LocalHospital, Remove} from "@mui/icons-material"
import LeafIcon from "../components/atoms/LeafIcon"

const statusIcons = {
    "Agriculture": LeafIcon,
    "ContentCut": ContentCut,
    "LocalHospital": LocalHospital,
    "HealthAndSafety": Favorite,
    "ShoppingBag": LocalMall,
    "SkipNext": FastForward,
    "Unknown": Help,
    "LocalFlorist": LocalFlorist,
    "DeleteForever": DeleteForever,
    "in": Add,
    "out": Remove
}

export default statusIcons