import {Duration, intervalToDuration} from 'date-fns';

export const formatDate = (date: Date | string) => {
    const dateVal: Date = typeof (date) === 'string' ? new Date(normalizeDateFormat(date)) : date
    return `${dateVal.getDate().toString().padStart(2, '0')}.${(dateVal.getMonth() + 1).toString().padStart(2, '0')}.${dateVal.getFullYear()}`
}

export const normalizeDateFormat = (value: string): string => {
    if (value.indexOf('.') === -1) {
        return value;
    }
    return value.split('.').reverse().join('-')
}

export const getDuration = (date: Date | number) => {
    let duration = intervalToDuration({
        start: new Date(),
        end: new Date(date)
    }) as Required<Duration>

    return duration
}