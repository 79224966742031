import {actionIds, BaseAction} from "../common";
import {IReportState} from "../../types/IReportState";
import {addDays, format, subMonths} from "date-fns";

// Date
const oneMonthAgo = subMonths(new Date(), 1)
const startDate = format(oneMonthAgo, 'yyyy-MM-dd')
const endDate = format(new Date(), 'yyyy-MM-dd')
const followingDay = format(addDays(new Date(endDate), 1), 'yyyy-MM-dd')

const defaultState: IReportState = {
    reportQuery: {
        farm_id: 0,
        zone_id: 0,
        user_id: 0,
        crop_status_id: 0,
        startDate: startDate,
        endDate: endDate,
        endDateOffset: followingDay,
        offset: 0,
        limit: 25
    },
    lot_data: [],
    has_more: true,
    image_url: [],
    graph_data: [],
    graph_table_data: []
}

const reportReducer = (state: IReportState = defaultState, action: BaseAction): IReportState => {
    switch (action.type) {
        case actionIds.SET_REPORTS_QUERY: 
            return {
                ...state,
                reportQuery: {
                    ...state.reportQuery,
                    ...action.payload
                }
            }
        
        case actionIds.UNSET_REPORTS_QUERY:
            return {
                ...state,
                reportQuery: {
                    ...defaultState.reportQuery
                }
            }
        
        case actionIds.SET_REPORTS_ACTIVITY:
            return {
                ...state,
                lot_data: action.payload.lot_data === null ? [] : [...state.lot_data, ...action.payload.lot_data],
                has_more: action.payload.has_more,
                reportQuery: {
                    ...state.reportQuery,
                    offset: state.lot_data.length
                }
            }
        
        case actionIds.SET_REPORTS_IMAGE_URL:
            if (action.payload.lot_data === null) {
                action.payload.lot_data = []
            } else {
                action.payload.lot_data = action.payload.lot_data.filter(data => data.image_url !== "")
            }
            return {
                ...state,
                image_url: [...action.payload.lot_data]
            }

        case actionIds.SET_REPORTS_GRAPH:
            if (action.payload.graph_data === null) {
                action.payload.graph_data = []
            } else {
                action.payload.graph_data.sort((a, b) => a.date.localeCompare(b.date))
            }

            return {
                ...state,
                graph_data: [...action.payload.graph_data],
                graph_table_data: [...action.payload.graph_data].reverse()
            }

        case actionIds.UNSET_REPORTS_ACTIVITY:
            return {
                ...state,
                lot_data: [],
                has_more: true,
                graph_data: [],
                graph_table_data: []
            }
            
        default:
            return state
    }
}

export default reportReducer;