import React from 'react';
import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import getWarehouseListAction from "../../../state/actions/getWarehouseList";
import PageLayout from "../PageLayout/PageLayout";
import {PageHeader} from "../PageHeader/PageHeader";
import LayoutContainer from "../../atoms/LayoutContainer/LayoutContainer";
import {ArrowRight} from "../../atoms/ArrowRight";
import {IRootState} from "../../../types/IRootState";
import {IWarehouseState} from "../../../types/IWarehouseState";
import routes from "../../../config/routes";
import {ChangeCircle} from "@mui/icons-material";


const WarehouseList = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {warehouses} = useSelector<IRootState>(state => state.warehouse) as IWarehouseState

    React.useEffect(() => {
        if (warehouses.length === 0) {
            // Load data if there is no warehouse
            dispatch(getWarehouseListAction())
        } else if (warehouses.length === 1) {
            // Redirect if there is only a single warehouse and user does not have permission to create new ones
            // handleWarehouseDetails(warehouses[0].id)
        }
    }, [dispatch, warehouses])

    const handleWarehouseCreateTransaction = (id) => {
        navigate(routes.WAREHOUSE_CREATE_TRANSACTION.replace(':id', id))
    }

    const handleWarehouseDetails = (id) => {
        navigate(routes.WAREHOUSE_DETAILS.replace(':id', id))
    }

    return (
        <PageLayout>
            <PageHeader caption="Management / Warehouses"/>
            <LayoutContainer>
                {warehouses && warehouses.map((item, index) => {
                    return (
                        <List key={index}>
                            <ListItem component="div">
                                <ListItem disableGutters component="div" onClick={() => {
                                    handleWarehouseDetails(item.id)
                                }}>
                                    <ListItemText primary={item.name}/>
                                </ListItem>
                                <ListItem disableGutters component="div">
                                    {/*<ListItemText primary={`${item.zones?.length} Zones`} style={{ textAlign: 'right' }} />*/}
                                </ListItem>
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={() => {handleWarehouseCreateTransaction(item.id)}}>
                                        <ChangeCircle />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="details" onClick={() => {handleWarehouseDetails(item.id)}}>
                                        <ArrowRight color={'currentColor'} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                })}
            </LayoutContainer>
        </PageLayout>
    )
}

export default WarehouseList