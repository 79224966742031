import React, {useState} from 'react';
import {Button, Grid, InputAdornment, ListItemText, TextField, Tooltip} from "@mui/material";
import {HelpOutline,LocationOn} from '@mui/icons-material';
import axios from 'axios';
import {getHostUrl} from "../../api/axios/api";
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {IRootState} from '../../types/IRootState';
import IAdminState from '../../types/IAdminState';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import {PageHeader} from "./PageHeader/PageHeader";
import PageLayout from "./PageLayout/PageLayout";

const AddFarms = () => {
    const navigate = useNavigate()
    const {selectedFarm} = useSelector<IRootState>(state => state.admin) as IAdminState
    const [zoneName, setZoneName] = useState('')
    const [lotsRow, setLotsRow] = useState('')
    const [lotsColumns, setLotsColumns] = useState('')
    const hostUrl = getHostUrl();

    let version = 1

    const handleChangeZoneName = (zoneName) => {
        setZoneName(zoneName)
    }

    const handleChangeLotsRow = (lotsRow) => {
        setLotsRow(lotsRow)
    }

    const handleChangeLotsColumn = (lotsColumns) => {
        setLotsColumns(lotsColumns)
    }

    const handleSubmit = () => {
        let data = {
            name: zoneName,
            farm_id: selectedFarm,
            lots_rows: parseInt(lotsRow),
            lots_columns: parseInt(lotsColumns)
        }
        
        axios.post(`${hostUrl}/api/v1/zones/create`, data, {withCredentials: true}).then((res) => {
            if (res.data) {
                navigate('/administration/farms')
            }
        })
    }

    return (
        <PageLayout>
            <PageHeader caption="Management / Farms / Add new farm (1/2)"/>
            <LayoutContainer>
                {/* Name */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Name:" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={zoneName}
                            onChange={evt => handleChangeZoneName(evt.target.value)}
                        />
                    </Grid>
                </Grid>

                {/* Lots total */}
                {version !== 1 && <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Lots total:" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="lots"
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </Grid>}

                {/* Address */}
                {version !== 1 && <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Address:" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="address"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <LocationOn style={{height: 20, top: 12, right: 5, position: "absolute", color: '#bdbdbd'}}/>
                                </InputAdornment>
                            }}
                        />
                    </Grid>
                </Grid>}

                {/* Max rows */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Max rows:" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="rows"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Tooltip arrow title={'Tooltip placeholder'} leaveTouchDelay={2000} enterTouchDelay={50} style={{height: 20, top: 12, right: -30, position: "absolute", color: '#bdbdbd'}}>
                                        <HelpOutline />
                                    </Tooltip>
                                </InputAdornment>
                            }}
                            value={lotsRow}
                            onChange={evt => handleChangeLotsRow(evt.target.value)}
                        />
                    </Grid>
                </Grid>

                {/* Max columns */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Max columns:" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="columns"
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Tooltip arrow title={'Tooltip placeholder'} leaveTouchDelay={2000} enterTouchDelay={50} style={{height: 20, top: 12, right: -30, position: "absolute", color: '#bdbdbd'}}>
                                        <HelpOutline />
                                    </Tooltip>
                                </InputAdornment>
                            }}
                            value={lotsColumns}
                            onChange={evt => handleChangeLotsColumn(evt.target.value)}
                        />
                        <Grid item style={{marginTop: 30}}>
                            <Button size="small" variant="contained" style={{width: 150}} onClick={() => handleSubmit()}>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </LayoutContainer>
        </PageLayout>
    )
}

export default AddFarms