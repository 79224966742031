import Resizer from "react-image-file-resizer";

type TImageData = string | Blob | ProgressEvent<FileReader> | File

const useMediaPicker = (
    callback?: (values: TImageData) => void
) => {
    const handleOpenGallery = (e: React.ChangeEvent) => {
        const selectedImage = (e.target as HTMLInputElement)?.files?.[0];
        const types = ['image/png', 'image/jpeg'];

        if (selectedImage && types.includes(selectedImage.type)) {
            // Resized image
            Resizer.imageFileResizer(
                selectedImage, // Is the file of the image which will resized.
                500, // Is the maxWidth of the resized new image.
                500, // Is the maxHeight of the resized new image.
                "JPEG", // Is the compressFormat of the resized new image.
                100, // Is the quality of the resized new image.
                0, // Is the degree of clockwise rotation to apply to uploaded image.
                (uri) => {
                    // Is the callBack function of the resized new image URI.
                    callback && callback(uri)
                },
                "base64", // Is the output type of the resized new image.
                200, // Is the minWidth of the resized new image.
                200 // Is the minHeight of the resized new image.
            );

        } else {
            callback && callback('error')
        }
    };

    return {
        handleOpenGallery
    }
}

export default useMediaPicker