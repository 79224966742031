import React, {ReactNode} from "react";
import './LabelledOutline.scss';

interface Props {
    className?: string;
    children?: ReactNode;
    id?: string;
    label?: string;
}

const LabelledOutline = (props: Props) => {
    return (
        <fieldset className="labelled-outline__fieldset">
            <legend className="labelled-outline__legend">{props.label}</legend>
            {props.children}
        </fieldset>
    )
}

export default LabelledOutline