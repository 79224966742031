import React, {useEffect} from 'react'
import PageLayout from '../organisms/PageLayout/PageLayout'
import {PageHeader} from "../organisms/PageHeader/PageHeader";
import {Badge, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {ExitToApp, Sync} from "@mui/icons-material";
import submitLogoutAction from "../../state/actions/submitLogout";
import LayoutContainer from "../atoms/LayoutContainer/LayoutContainer";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import routes from "../../config/routes";
import './ProfilePage.scss'
// import {getUnsyncedHarvestDataCount} from "../../config/db";
import {IRootState} from '../../types/IRootState';
import {ISyncState} from '../../types/ISyncState';
import getSyncQueue from '../../state/actions/getSyncQueue';
import {APP_VERSION} from "../../config/version";

const  ProfilePage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {unsyncedItems} = useSelector<IRootState>(state => state.sync) as ISyncState

    // TODO: move this into a separate state / reducer
    // const [unsyncedItems, setUnsyncedItems] = useState(0)
    // useEffect(() => {
    //     getUnsyncedHarvestDataCount().then((results) => {
    //         setUnsyncedItems(results)
    //     })
    // }, [])

    useEffect(() => {
        dispatch(getSyncQueue())
    }, [dispatch])

    return (
        <div>
            <PageLayout>
                <PageHeader caption="Profile"/>
                <LayoutContainer>
                    <List>
                        <ListItemButton onClick={() => navigate(routes.PROFILE_SYNC_QUEUE)}>
                            <ListItemIcon>
                                {unsyncedItems > 0 && <Badge badgeContent={unsyncedItems} color="error">
                                    <Sync />
                                </Badge>}
                                {unsyncedItems === 0 && <Sync />}
                            </ListItemIcon>
                            <ListItemText primary="Sync Queue" />
                        </ListItemButton>
                        <ListItemButton onClick={() => dispatch(submitLogoutAction())}>
                            <ListItemIcon>
                                <ExitToApp />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItemButton>
                    </List>
                    <div className="profile-page__version">Version: <a href={routes.DEBUG} className="profile-page__version-link">{APP_VERSION.substr(0, 12)}</a></div>
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default ProfilePage
