import React from 'react'
import {
    Button,
    Grid,
    ListItemText,
    SvgIcon,
    SvgIconProps,
} from '@mui/material';

interface IData {
    name: string;
    icon?: (props: SvgIconProps) => JSX.Element;
    action?: string;
}

interface Props {
    data: IData[];
    selectedButton: string;
    onClick: (e) => void;
}

const ButtonCategories = (props: Props) => {
    return (
        <Grid container>
            {props.data.map((buttonCategory, i) => {
                return (
                    <Grid item xs key={i}>
                        <div style={props.selectedButton === buttonCategory.name ? { borderBottom: '2px solid #3f51b5'} : {}}>
                            <Button
                                sx={{
                                    "&:hover": {
                                        background: 'none'
                                    },
                                    flexDirection: 'column'
                                }}
                                fullWidth
                                disableRipple
                                onClick={() => props.onClick(buttonCategory)}
                            >
                                {buttonCategory.icon && <SvgIcon component={buttonCategory.icon} style={{ color: props.selectedButton === buttonCategory.name ? '#3f51b5' : '#757575' }} />}
                                <ListItemText primary={buttonCategory.name} style={{ color: props.selectedButton === buttonCategory.name ? '#3f51b5' : '#757575' }} />
                            </Button>
                        </div>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ButtonCategories