import React, { useState } from 'react';
import { Box, Button, Grid, ListItemText, MenuItem, TextField } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import { PageHeader } from "./PageHeader/PageHeader";
import PageLayout from "./PageLayout/PageLayout";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';

const languages = [
    'English',
    'Language 2',
    'Language 3'
];

const AddUsers = () => {
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('49')
    const [language, setLanguage] = React.useState('English');

    const handleChangeName = (name) => {
        setName(name)
    }

    const handleChangeEmail = (email) => {
        setEmail(email)
    }

    const handleChangePhone = (phone) => {
        setPhone(phone)
    }

    const handleChangeLanguage = (language) => {
        setLanguage(language);
    };


    const handleSubmit = () => {
        // TODO: redux
        navigate('/administration/users')
    }

    return (
        <PageLayout>
            <PageHeader caption="Management > Users > Add new" />
            <LayoutContainer>
                {/* Name */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Name:" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder={'Username'}
                            value={name}
                            onChange={evt => handleChangeName(evt.target.value)}
                        />
                    </Grid>
                </Grid>

                {/* Email */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="E-Mail:" />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="email"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={email}
                            onChange={evt => handleChangeEmail(evt.target.value)}
                        />
                    </Grid>
                </Grid>

                {/* Phone */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Phone:" />
                    </Grid>
                    <Grid item xs={8}>
                        <PhoneInput
                            autoFormat={false}
                            onlyCountries={['de', 'my']}
                            placeholder={''}
                            value={phone}
                            onChange={phone => handleChangePhone(phone)}
                            inputStyle={{ width: '100%' }}
                            containerStyle={{ margin: '8px 0px' }}
                            dropdownStyle={{ width: 'auto', whiteSpace: 'nowrap' }}
                        />
                    </Grid>
                </Grid>

                {/* Language */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Language:" />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            id="language"
                            variant="outlined"
                            size="small"
                            fullWidth
                            select
                            value={language}
                            onChange={evt => handleChangeLanguage(evt.target.value)}
                            SelectProps={{
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    // getContentAnchorEl: null
                                },
                            }}
                        >
                            {languages.map((language, i) => (
                                <MenuItem key={i} value={language}>
                                    {language}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>

                {/* Password */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                        <ListItemText primary="Password:" />
                    </Grid>
                    <Grid item xs={8}>
                        <ListItemText style={{ color: '#bdbdbd' }} primary="(will be sent via e-mail or sms)" />
                    </Grid>
                </Grid>

                <Box pt={2} display="flex" justifyContent="flex-end">
                    <Button size="small" variant="contained" style={{ width: 150 }} onClick={() => { handleSubmit() }}>
                        Save
                    </Button>
                </Box>
            </LayoutContainer>
        </PageLayout>
    )
}

export default AddUsers