import React from 'react';
import UpdateStatus1 from '../organisms/Harvest/UpdateStatus1';
import PageLayout from '../organisms/PageLayout/PageLayout';

const UpdateStatusPage1 = (props: any) => {
    return (
        <div className="">
            <PageLayout>
                <UpdateStatus1 />
            </PageLayout>
        </div>
    )
}

export default UpdateStatusPage1