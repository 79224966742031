import React from 'react';
import {Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer';
import {IRootState} from '../../types/IRootState';
import IAdminState from '../../types/IAdminState';
import setZoneSelectedAction from '../../state/actions/setZoneSelected';
import getZonesDetailsAction from '../../state/actions/getZonesDetails';
import loadingSet from '../../state/actions/loadingSet';
import {PageHeader} from "./PageHeader/PageHeader";
import PageLayout from "./PageLayout/PageLayout";
import {ArrowRight} from "../atoms/ArrowRight";
import routes from "../../config/routes";

const Zones = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { farms, selectedFarm, zones } = useSelector<IRootState>(state => state.admin) as IAdminState
    let farm = farms.find((farm: any) => farm.id === selectedFarm)

    const handleZonesDetails = (id) => {
        navigate(routes.ADMIN_FARMS_ZONES_DETAILS)
        dispatch(loadingSet())
        dispatch(setZoneSelectedAction({
            selectedZone: id
        }))
        dispatch(getZonesDetailsAction({
            zoneId: id
        }))
    }

    return (
        <PageLayout>
            <PageHeader caption="Management / Zones"/>
            <LayoutContainer>
                {farm?.zones?.map((item, index) => {
                    let zone = zones?.find((zone: any) => zone.name === item.name)
                    return (
                        <List key={index}>
                            <ListItem component="div">
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={item.name} />
                                </ListItem>
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={`${zone?.lots_total} Lots`} style={{ textAlign: 'right' }} />
                                </ListItem>
                                <ListItemSecondaryAction onClick={() => {handleZonesDetails(item.id)}}>
                                    <IconButton edge="end" aria-label="edit" >
                                        <ArrowRight color={'currentColor'} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                })}
                <Button size="small" variant="contained" style={{width: 150}} onClick={() => navigate(routes.ADMIN_FARMS_ZONES_ADD)}>
                    Add Zone
                </Button>
            </LayoutContainer>
        </PageLayout>
    )
}

export default Zones