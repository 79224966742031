import React from 'react';
import {
    Button,
    Card,
    CardMedia,
    Divider,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    SvgIcon,
    TextField,
    Typography
} from '@mui/material';
import {Close, PhotoCamera} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {IRootState} from '../../../types/IRootState';
import IHarvestState from "../../../types/IHarvestState";
import setHarvestDataAction from '../../../state/actions/setHarvestData';
import loadingUnsetAction from '../../../state/actions/loadingUnset';
import routes from '../../../config/routes';
import statusIcons from '../../../config/statusIcons';
import {saveHarvestData} from '../../../config/db';
import {PageHeader} from "../PageHeader/PageHeader";
import {ICropsState} from "../../../types/ICropsState";
import IUserState from "../../../types/IUserState";
import resetHarvestData from "../../../state/actions/resetHarvestData";
import useMediaPicker from '../../../hooks/useMediaPicker';
import ProgressBar from '../../atoms/ProgressBar/ProgressBar';

const UpdateStatus3 = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        lot_id,
        weight,
        amount,
        notes,
        imageData,
        lot,
        lot_crop_id,
        crop_index,
        crop_id,
        status_id,
    } = useSelector<IRootState>(state => state.harvest) as IHarvestState

    const {crops} = useSelector<IRootState>(state => state.crops) as ICropsState
    const {gpsPosition} = useSelector<IRootState>(state => state.user) as IUserState

    const crop = crops.find(crop => crop.id.toString(10) === crop_id)
    const status = crop?.statuses?.find((status: any) => status.id === status_id)

    const {handleOpenGallery} = useMediaPicker((image) => handleChangeSelectImage(image))

    if (!crop || !status) {
        alert('Error: Could not find crop status, try to log out and log in again to refresh your data')
        navigate(routes.PROFILE)
        return null
    }

    const disableSubmit = () => {
        if (status.required_image && !imageData) {
            return true
        } else if (status.required_notes && !notes) {
            return true
        }
        return false
    }

    const handleChangeSelectImage = (image) => {
        if (image === 'error') {
            alert('Error: Unsupported file format')
        } else {
            dispatch(setHarvestDataAction({
                imageData: image
            }))
        }
    }

    const handleChangeWeight = (weight) => {
        dispatch(setHarvestDataAction({
            weight: weight
        }))
    }

    const handleChangeAmount = (amount) => {
        dispatch(setHarvestDataAction({
            amount: amount
        }))
    }

    const handleChangeNotes = (notes) => {
        dispatch(setHarvestDataAction({
            notes: notes
        }))
    }

    const handleSubmit = () => {
        // dispatch(submitHarvestAction({
        //     lot_id: lot_id?.lot_id,
        //     weight: weight?.weight,
        //     notes: notes?.notes,
        //     imageData: imageData?.imageData
        // }))

        // Remove prefix
        let base64 = ''
        if(imageData) {
            base64 = imageData.split(',')[1];
        }

        // save to indexDB
        saveHarvestData({
            lot_id: lot_id && parseInt(lot_id),
            lot_crop_id: lot_crop_id && parseInt(lot_crop_id),
            // lot_crop_index: lot_crop_index && parseInt(lot_crop_index),
            status_id: status_id && parseInt(status_id),
            weight: weight && parseFloat(weight),
            amount: amount && parseFloat(amount),
            notes: notes,
            gpsPosition: gpsPosition,
            imageData: base64
        })

        dispatch(loadingUnsetAction())
        dispatch(resetHarvestData())
        navigate(routes.SCAN_QR_PAGE)
    }

    return (
        <div style={{marginBottom: 100}}>
            {/* Header */}
            <List disablePadding style={{background: '#f4f4f4'}}>
                <ListItem disableGutters>
                    <ListItemText
                        primary={<Typography variant="h5" align="center"
                                             style={{marginTop: -2, marginBottom: -5}}>{lot?.zone}</Typography>}
                        secondary={<Typography variant="body2" align="center">Zone</Typography>}
                        style={{textAlign: 'center'}}
                    />

                    <Divider orientation="vertical" flexItem/>

                    <ListItemText primary={lot?.name} style={{paddingLeft: 15}}/>
                </ListItem>
            </List>

            {/* Lot Action Status */}
            <PageHeader caption="Lot Actions > Update Status (3/3)"/>

            {/* Progress Bar */}
            <List style={{background: '#f4f4f4'}}>
                <ListItem>
                    <ListItemText primary={`Tree #${crop_index}`}/>
                    <ListItemText primary={<ProgressBar variant="determinate" value={100}/>}/>
                </ListItem>
            </List>

            <Card variant="outlined" style={{padding: 20}}>
                {/* New Status */}
                <ListItemText primary="New Status:"/>

                <ListItem disableGutters>
                    <SvgIcon component={statusIcons[status.icon]} style={{color: status.color}}/>
                    <ListItemText primary={status.name} style={{color: status.color}}/>
                </ListItem>

                {/* Notes */}
                <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                        <ListItemText primary="Notes: (optional)"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="notes"
                            variant="outlined"
                            size="small"
                            fullWidth
                            multiline
                            minRows={4}
                            maxRows={4}
                            value={notes}
                            onChange={evt => handleChangeNotes(evt.target.value)}
                        />
                    </Grid>
                </Grid>

                <div style={{marginTop: 10}}/>

                {/* Weight */}
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <ListItemText primary="Weight:"/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="weight"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{crop.weight_unit}</InputAdornment>
                            }}
                            value={weight}
                            onChange={evt => handleChangeWeight(evt.target.value)}
                        />
                    </Grid>
                </Grid>

                {/* Amount */}
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <ListItemText primary="Amount:"/>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="amount"
                            variant="outlined"
                            size="small"
                            type="number"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{crop.amount_unit}</InputAdornment>
                            }}
                            value={amount}
                            onChange={evt => handleChangeAmount(evt.target.value)}
                        />
                    </Grid>
                </Grid>

                <div>&nbsp;</div>

                {/* Image */}
                <ListItemText primary="Photo: (optional)"/>

                {!imageData ?
                    <Card variant="outlined" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 200,
                        position: 'relative'
                    }}>
                        <label htmlFor="icon-button-file">
                            <input id="icon-button-file"
                                   type="file"
                                   accept="image/*"
                                   hidden
                                   onChange={handleOpenGallery}
                            />
                            <IconButton component="span">
                                <PhotoCamera style={{fontSize: 40}}/>
                            </IconButton>
                        </label>
                    </Card> :
                    <Card variant="outlined" style={{height: 200, position: 'relative'}}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={imageData}
                        />

                        <Fab color="primary" size="small" aria-label="close" style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: '#F50A00'
                        }} onClick={() => {
                            dispatch(setHarvestDataAction({
                                imageData: ''
                            }))
                        }}>
                            <Close/>
                        </Fab>
                    </Card>}

                <div style={{marginTop: 15}}/>

                <Button
                    size="small"
                    variant="contained"
                    style={{width: 150}}
                    disabled={disableSubmit()}
                    onClick={() => handleSubmit()}
                >
                    Save
                </Button>
            </Card>
        </div>
    )
}

export default UpdateStatus3
