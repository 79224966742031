import {actionIds, BaseAction} from "../common";
import IHarvestState from "../../types/IHarvestState";

const setHarvestDataAction: (data: IHarvestState) =>
    BaseAction = (data) => ({
    type: actionIds.SET_HARVEST_DATA,
    payload: {data},
});

export default setHarvestDataAction;
