import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import PageLayout from '../PageLayout/PageLayout';
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import {PageHeader} from '../PageHeader/PageHeader';
import {listErrorLogs} from "../../../config/db";

interface ErrorLog {
    id?: number;
    url: string;
    data?: string;
    data_hash: string;
    error: string;
    created_at: number;
}

const DebugErrorLogs = () => {
    const [errorLogs, setErrorLogs] = useState([] as ErrorLog[])
    
    useEffect(() => {
        (async () => {
            const dbData = await listErrorLogs()
            setErrorLogs(dbData)
        })()
    }, [])

    return (
        <PageLayout>
            <PageHeader caption="Debug > Error Logs" />
            <LayoutContainer className="layout-container-pad0">
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="center">url</TableCell>
                                <TableCell align="center">data</TableCell>
                                <TableCell align="center">data_hash</TableCell>
                                <TableCell align="center">error</TableCell>
                                <TableCell align="center">created_at</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {errorLogs.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="center">{row.url}</TableCell>
                                    <TableCell align="center">{row.data}</TableCell>
                                    <TableCell align="center">{row.data_hash}</TableCell>
                                    <TableCell align="center">{row.error}</TableCell>
                                    <TableCell align="center">{row.created_at}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LayoutContainer>
        </PageLayout>
    )
}

export default DebugErrorLogs