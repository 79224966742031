import React, { useCallback, useEffect, useState } from 'react'
import {
    Grid,
    ListItem,
    ListItemText,
    useMediaQuery,
    useTheme
} from '@mui/material';
// import { ArgumentAxis, Chart, ValueAxis, LineSeries, Legend } from '@devexpress/dx-react-chart-material-ui';
// import { Animation } from '@devexpress/dx-react-chart';
import {
    Chart,
    Series,
    ArgumentAxis,
    Legend,
    Point
} from 'devextreme-react/chart';
import { format } from 'date-fns';
import PageLayout from '../PageLayout/PageLayout'
import { PageHeader } from "../PageHeader/PageHeader";
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import axios from 'axios';
import { getHostUrl } from "../../../api/axios/api";
import FormInput from '../../atoms/FormInput';

const WorkerOverview = () => {
    const theme = useTheme()
    const desktopViewport = useMediaQuery(theme.breakpoints.up('sm'));
    const [workerActivity, setWorkerActivity] = useState<any[]>([])
    const hostUrl = getHostUrl();
    const [startDate, setStartDate] = useState('2021-10-01')
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'))

    const getWorkerData = useCallback(() => {
        let url = `${hostUrl}/api/v1/reports/worker-activity?farm_id=0&zone_id=0&user_id=0&from=${startDate}&to=${endDate}`
        axios.get(url, { withCredentials: true }).then((res) => {
            if (res.data) {
                // Reset state if response === null
                if (res.data.data === null) {
                    setWorkerActivity([])
                } else {
                    setWorkerActivity(res.data.data)
                }
            }
        }).catch((err) => {
            console.log('error', err);
        })
    }, [hostUrl, startDate, endDate])

    useEffect(() => {
        getWorkerData()
    }, [getWorkerData])

    const handleChangeDate = (event) => {
        switch (event.target.id) {
            case 'startDate':
                setStartDate(event.target.value);
                getWorkerData()
                break;

            case 'endDate':
                setEndDate(event.target.value);
                getWorkerData()
                break;

            default:
                break;
        }
    };

    const renderLineSeries = data => Object
        .keys(data[0])
        .reduce<any>((acc, item, index) => {
            if (item !== 'date') {
                acc.push(
                    <Series
                        key={index.toString()}
                        valueField={item}
                        argumentField="date"
                        name={item}
                    >
                        <Point visible={false} />
                    </Series>
                );
            }
            return acc;
        }, []);

    const renderLineChart = data => {
        const jsonData = data.reduce((acc, item) => {
            const currentSubmissions = item.submissions.reduce((current, submission) => {
                let currentObj = {};
                currentObj = { [submission.user]: submission.amount };
                return { ...current, ...currentObj };

            }, []);

            return [...acc, { date: item.date.substr(0, 10), ...currentSubmissions }]
        }, []);

        // Sort array object by date
        jsonData.sort((a, b) => {
            return a.date.localeCompare(b.date);
        })

        return (
            // dev express
            // <Chart
            //     data={jsonData}
            //     height={window.innerHeight - 380}
            // >
            //     <ArgumentAxis
            //         showTicks={false}
            //     />
            //     <ValueAxis />

            //     {renderLineSeries(jsonData)}
            //     {/* <Animation /> */}

            //     <Legend position="bottom" />
            // </Chart>

            // dev extreme
            <Chart
                dataSource={jsonData}
                style={{
                    height: desktopViewport ? 350 : window.innerHeight - 380
                }}
            >
                {renderLineSeries(jsonData)}
                <ArgumentAxis
                    valueMarginsEnabled={false}
                    discreteAxisDivisionMode="crossLabels"
                />
                <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="bottom"
                />
            </Chart>
        );
    };

    return (
        <div>
            <PageLayout>
                {/* Header */}
                <PageHeader caption="Administration > Reports > Worker Overview" />

                <LayoutContainer className="layout-container-pad0">
                    <div style={{ padding: '0px 8px' }}>
                        {/* Row 1 */}
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={6}>
                                <FormInput
                                    id="startDate"
                                    label="From:"
                                    type="date"
                                    size={12}
                                    value={startDate}
                                    onChange={handleChangeDate}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormInput
                                    id="endDate"
                                    label="To:"
                                    type="date"
                                    size={12}
                                    value={endDate}
                                    onChange={handleChangeDate}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{ paddingBottom: 8 }} />
                    <ListItem style={{ backgroundColor: '#6aa84f' }}>
                        <ListItemText primary={'Submissions per day'} style={{ color: '#FFFFFF' }} />
                    </ListItem>

                    {/* LineChart */}
                    {workerActivity.length ?
                        renderLineChart(workerActivity) :
                        <ListItemText primary={'Information not available'} style={{ textAlign: 'center' }} />}
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default WorkerOverview