import {
    FormControl,
    MenuItem,
    Select,
    // TextField,
} from '@mui/material';
import classNames from 'classnames';

interface Props {
    className?: string;
    label?: string;
    error?: string;
    // onChange?: (evt: ChangeEvent<HTMLInputElement>) => void; // Textfield
    // onChange?: (evt: ChangeEvent<{}>, value: any) => void; // Select
    onChange?: any;
    value?: any;
    options?: any[];
}

const FormDropdown = (props: Props) => {
    return (
        <div className={classNames('form-dropdown', props.className)}>
            {/* Select Version */}
            <FormControl variant="filled" size="small" fullWidth>
                <Select
                    variant="outlined"
                    value={props.value === 0 ? '' : props.value}
                    onChange={props.onChange}
                    displayEmpty
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        // getContentAnchorEl: null
                    }}
                >
                    {/* Default Value */}
                    <MenuItem value="">
                        <em>{props.label}</em>
                    </MenuItem>

                    {/* Example*/}
                    {/* {id: 1, name: 'Bananafarm' …} */}
                    {props.options?.map((option, i) => (
                        <MenuItem key={i} value={option.id}>
                            {option.name || option.crop}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Textfield Version */}
            {/* <TextField
                id=""
                variant="outlined"
                size="small"
                fullWidth
                select
                value={props.value}
                onChange={props.onChange}
                inputProps={{ className: classes.select }}
                SelectProps={{
                    MenuProps: {
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    },
                    classes: { icon: classes.dropdownIcon },
                    renderValue: (value: any) => value
                }}
            >
                <MenuItem value="" id={''}>
                    <em>Default Value</em>
                </MenuItem>
                {props.options.map((option, i) => (
                    <MenuItem className={classes.menuItem} key={i} id={option.id?.toString() || 'id'} value={option.name}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField> */}
        </div>
    )
}

export default FormDropdown