import React from 'react'
import './Loader.scss'

const Loader = () => {
    return (
        <div className="loader-container">
            <div className="dot-pulse" />
        </div>
    )
}

export default Loader
