import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import PageLayout from '../PageLayout/PageLayout';
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import {PageHeader} from '../PageHeader/PageHeader';
import {listLogDataHistory} from "../../../config/db";

interface LotDataHistory {
    id?: number;
    lot_id: number;
    crop_status_id: number;
    amount: number;
    weight: number;
    notes: string;
    scanned_at: number;
    image_hash: string;
}

const DebugLogDataHistory = () => {
    const [logDataHistory, setLogDataHistory] = useState([] as LotDataHistory[])
    
    useEffect(() => {
        (async () => {
            const dbData = await listLogDataHistory()
            setLogDataHistory(dbData)
        })()
    }, [])

    return (
        <PageLayout>
            <PageHeader caption="Debug > Log Data History" />
            <LayoutContainer className="layout-container-pad0">
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="center">lot_id</TableCell>
                                <TableCell align="center">crop_status_id</TableCell>
                                <TableCell align="center">amount</TableCell>
                                <TableCell align="center">weight</TableCell>
                                <TableCell align="center">notes</TableCell>
                                <TableCell align="center">scanned_at</TableCell>
                                <TableCell align="center">image_hash</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logDataHistory.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="center">{row.lot_id}</TableCell>
                                    <TableCell align="center">{row.crop_status_id}</TableCell>
                                    <TableCell align="center">{row.amount}</TableCell>
                                    <TableCell align="center">{row.weight}</TableCell>
                                    <TableCell align="center">{row.notes}</TableCell>
                                    <TableCell align="center">{row.scanned_at}</TableCell>
                                    <TableCell align="center">{row.image_hash}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LayoutContainer>
        </PageLayout>
    )
}

export default DebugLogDataHistory