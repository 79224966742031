import {actionIds, BaseAction} from "../common";
import {ICropsState} from "../../types/ICropsState";

const defaultState: ICropsState = {
    crops: [],
}

const cropsReducer = (state: ICropsState = defaultState, action: BaseAction): ICropsState => {
    switch (action.type) {
        case actionIds.SET_CROPS_LIST:
            return {
                ...state,
                crops: action.payload.crops
            }

        default:
            return state
    }
}

export default cropsReducer;
