import React, {useEffect, useState} from 'react';
import {IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {MoreVert} from '@mui/icons-material';
import {format, formatISO, parseISO} from "date-fns";
import PageLayout from '../PageLayout/PageLayout';
import LayoutContainer from '../../atoms/LayoutContainer/LayoutContainer';
import {PageHeader} from '../PageHeader/PageHeader';
import {listScannedQrHistory} from "../../../config/db";

interface ScannedQrHistory {
    id?: number;
    lot_id: number;
    raw_data: string;
    scanned_at: number;
}

const DebugScannedData = () => {
    const [scannedQrHistory, setScannedQrHistory] = useState([] as ScannedQrHistory[])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [selectedIndex, setSelectedIndex] = useState(0)
    
    useEffect(() => {
        (async () => {
            const dbData = await listScannedQrHistory()
            setScannedQrHistory(dbData)
        })()
    }, [])

  
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    
    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setSelectedIndex(index)
        setAnchorEl(null)
    }
    
    const handleClose = () => {
        setAnchorEl(null)
    }

    const options = [
        'Epoch',
        'Format Date',
        'Format Time'
    ]

    return (
        <PageLayout>
            <PageHeader caption="Debug > Scanned Data" />
            <LayoutContainer className="layout-container-pad0">
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell align="center">lot_id</TableCell>
                                <TableCell align="center">raw_data</TableCell>
                                <TableCell align="center" style={{minWidth: '105px'}}>
                                    scanned_at
                                    <IconButton aria-label="list options" size="small" onClick={handleClickListItem}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>                      
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {scannedQrHistory.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="center">{row.lot_id}</TableCell>
                                    <TableCell align="center">{row.raw_data}</TableCell>
                                    <TableCell align="center">
                                        {selectedIndex === 0 ? row.scanned_at : 
                                            selectedIndex === 1 ? format(new Date(row.scanned_at), 'dd-MM-yyyy') :
                                            selectedIndex === 2 ? parseISO(formatISO(new Date(row.scanned_at))).toString() : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Menu Popper */}
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            </LayoutContainer>
        </PageLayout>
    )
}

export default DebugScannedData