import {actionIds, BaseAction} from "../common";

interface Prop {
    // zone id
    selectedZone: string
}

const setZoneSelected: (data: Prop) =>
    BaseAction = (data) => ({
    type: actionIds.SET_SELECTED_ZONE,
    payload: {
        ...data
    },
});

export default setZoneSelected;
