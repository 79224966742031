// Validations
import {formatDate, normalizeDateFormat} from "../lib/formatDate";

export const validations = {
    required: value => value ? undefined : 'This field is required.',
    phoneNumber: value => value && !/^0[1-9][0-9]{0,3}-[0-9]{1,12}$/i.test(value) ?
        'Bitte geben Sie Ihre alte Telefonnummer im folgenden Format 0231-1234567 mit gültiger Vorwahl ein.' : undefined,
    phoneNumberMY: value => value && !/^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/i.test(value) ?
        'Bitte geben Sie Ihre alte Telefonnummer im folgenden Format 0231-1234567 mit gültiger Vorwahl ein.' : undefined,
    maxLength: max => value =>
        value && value.length > max ? `Maximal ${max} Zeichen erlaubt` : undefined,
    minLength: min => value =>
        value && value.length < min ? `Mindestens ${min} Zeichen erforderlich` : undefined,
    minDate: (date: Date) => value =>
        parseDateToTime(value) < date.getTime() ? `Datum muss nach dem ${formatDate(date)} liegen` : undefined,
    maxDate: (date: Date) => value =>
        parseDateToTime(value) >= date.getTime() ? `Datum muss vor dem ${formatDate(date)} liegen` : undefined,
    minAge: (age) => value =>
        !checkAge(age, new Date(normalizeDateFormat(value))) ? `Sie müssen mindestens ${age} Jahre alt sein.` : undefined,
    email: value =>
        value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value) ?
            'E-Mail Adresse ist ungültig' : undefined,
    alphaNumeric: value =>
        value && !/^[a-z0-9äöüß&.,+ -]*$/i.test(value) ? 'Ungültige Zeichen, es sind nur A-Z, 0-9, Umlaute und . , + - & zulässig.' : undefined,
    numeric: value =>
        value && !/^[0-9.,]*$/i.test(value) ? 'Ungültige Zeichen, es sind nur Zahlen zulässig.' : undefined,
    iban: value =>
        value && !/^DE(?:[ ]?[0-9]){20}$/i.test(value) ? 'Es muss eine gültige IBAN (Deutschland) eingegeben werden.' : undefined,
};

export const validateInputs = (validateFields: {[index: string]: Function[]}, data: any): {[index: string]: string} => {
    const errorList = {};
    Object.keys(validateFields).forEach(fieldName => {
        validateFields[fieldName].forEach(validation => {
            const error = validation(data[fieldName])
            if (error && !errorList[fieldName]) {
                errorList[fieldName] = error
            }
        })
    })
    return errorList
}

export const parseDateToTime = (value: string): number => {
    return Date.parse(normalizeDateFormat(value))
}

const checkAge = (age, compDate) => {
    const now = new Date()
    if (compDate.getFullYear() < now.getFullYear() - age) {
        return true
    } else if (compDate.getFullYear() > now.getFullYear() - age) {
        return false
    } else {
        if (compDate.getMonth() > now.getMonth()) {
            return true
        } else if (compDate.getMonth() < now.getMonth()) {
            return false
        } else {
            if (compDate.getDate() <= now.getDate()) {
                return true
            } else {
                return false
            }
        }
    }
}