import React, {useState} from 'react'
import {Box, Button, Grid, ListItemText, Stack, TextField} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import PageLayout from '../organisms/PageLayout/PageLayout'
import {PageHeader} from '../organisms/PageHeader/PageHeader'
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer'
import setNotification from '../../state/actions/setNotification'
import {validations} from '../../config/validations';

const ForgotPasswordPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userInput, setUserInput] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    const handleChangeUserInput = (userInput) => {
        setUserInput(userInput)
    }

    const handleResetPassword = () => {
        if (!userInput) {
            dispatch(setNotification({
                type: 'error',
                message: 'E-Mail or phone number not found'
            }))
            return null
        } else if (validations.email(userInput) && validations.phoneNumberMY(userInput)) {
            dispatch(setNotification({
                type: 'error',
                message: 'Invalid E-Mail or phone number'
            }))
            return null     
        }

        setLoading(prevState => !prevState)
        setTimeout(() => {
            setLoading(prevState => !prevState)
            dispatch(setNotification({
                type: 'success',
                message: 'Password reset link has been sent'
            }))
        }, 1000)
    }
    return (
        <div>
            <PageLayout>
                <PageHeader caption='Forgot password' />
                <LayoutContainer>
                    <Stack spacing={3}>
                        &nbsp;
                        <div>
                            <p>To reset your password, you need to put in your e-mail or phone number.</p>
                            <p>We will send you a link that allows you to reset your password.</p>
                        </div>

                        <div>
                            <Grid container alignItems="flex-start">
                                <Grid item xs={12}>
                                    <ListItemText primary="E-mail or phone:" />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        placeholder="john@gmail.com"
                                        size="small"
                                        fullWidth
                                        value={userInput}
                                        onChange={evt => handleChangeUserInput(evt.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Box pt={2} gap={2} display="flex" justifyContent="flex-start">
                                <LoadingButton loading={loading} size="small" variant="contained" style={{ width: 150 }} onClick={() => { handleResetPassword() }}>
                                    Reset password
                                </LoadingButton>
                                <Button size="small" variant="outlined" style={{ width: 150 }} onClick={() => { navigate('/') }}>
                                    Cancel
                                </Button>
                            </Box>
                        </div>
                    </Stack>
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default ForgotPasswordPage