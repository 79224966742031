import {actionIds, BaseAction} from "../common";
import {IWarehouseInput} from "../../types/IWarehouseState";

const setCreateTransactionsDataAction: (data: IWarehouseInput) =>
    BaseAction = (data) => ({
    type: actionIds.SET_CREATE_WAREHOUSE_TRANSACTIONS_DATA,
    payload: {data},
});

export default setCreateTransactionsDataAction;
