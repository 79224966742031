import React, {useState} from 'react'
import {Box, Button, Grid, LinearProgress, ListItemText, Stack, TextField} from '@mui/material'
import {Warning} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useDispatch} from 'react-redux'
import PageLayout from '../organisms/PageLayout/PageLayout'
import {PageHeader} from '../organisms/PageHeader/PageHeader'
import LayoutContainer from '../atoms/LayoutContainer/LayoutContainer'
import setNotification from '../../state/actions/setNotification'
import {passwordValidation} from '../../lib/passwordValidation';

const ResetPasswordPage = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [password, setPassword] = useState({
        newPassword: '',
        repeatPassword: ''
    })

    const [passwordStrength, setPasswordStrength] = useState({
        strength: '',
        indicator: 0,
        color: ''
    })
    const handleChangeInput = (id: string, value: string) => {
        if (id === 'newPassword') {
            let result = passwordValidation(value)
            result && setPasswordStrength(result)
        }

        setPassword({
            ...password,
            [id]: value
        })
    }

    const handleResetPassword = () => {
        if (!password.newPassword) {
            dispatch(setNotification({
                type: 'error',
                message: 'Password is required'
            }))
            return null
        } else if (password.newPassword !== password.repeatPassword) {
            dispatch(setNotification({
                type: 'error',
                message: 'Password does not match'
            }))
            return null
        } else if (passwordStrength.strength === 'Poor') {
            dispatch(setNotification({
                type: 'error',
                message: 'Password is too simple'
            }))
            return null
        }

        setLoading(prevState => !prevState)
        setTimeout(() => {
            setLoading(prevState => !prevState)
            dispatch(setNotification({
                type: 'success',
                message: 'Your password has been reset successfully'
            }))
        }, 1000)
    }

    return (
        <div>
            <PageLayout>
                <PageHeader caption='Reset password' />
                <LayoutContainer>
                    {/* Link expired */}
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={3} pt={2}>
                        <div>
                            <Warning style={{fontSize: 50}} color='error'/>
                        </div>

                        <div>
                            <p>Unfortunately the link has already expired. Please request a new link to reset your password by clicking the button below.</p>
                            <Box pt={2} display="flex" justifyContent="flex-start">
                                <Button size="small" variant="contained" style={{ width: 150 }} onClick={() => { }}>
                                    Forgot password
                                </Button>
                            </Box>
                        </div>
                    </Stack>
                    {/*  */}

                    {/* Reset Password */}
                    <Stack spacing={3}>
                        &nbsp;
                        <div>
                            <p>Please set your new password. It needs to be a least 6 characters in length and have numbers as well as letters.</p>
                        </div>

                        <div>
                            <Grid container alignItems="flex-start">
                                <Grid item xs={4}>
                                    <ListItemText primary="Password:" />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        id="newPassword"
                                        size="small"
                                        fullWidth
                                        type="password"
                                        value={password.newPassword}
                                        onChange={evt => handleChangeInput('newPassword', evt.target.value)}
                                    />

                                    {password.newPassword && <Box sx={{ width: '100%', color: passwordStrength.color, backgroundColor: '#f4f4f4', mt: 1 }}>
                                        <LinearProgress color="inherit" variant="determinate" value={passwordStrength.indicator} />
                                    </Box>}

                                    {password.newPassword && <p>{`Strength: ${passwordStrength.strength}`}</p>}
                                </Grid>
                            </Grid>

                            <Grid container alignItems="flex-start">
                                <Grid item xs={4}>
                                    <ListItemText primary="Repeat:" />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                            id="repeatPassword"
                                            size="small"
                                            fullWidth
                                            type="password"
                                            value={password.repeatPassword}
                                            onChange={evt => handleChangeInput('repeatPassword', evt.target.value)}
                                        />
                                    <Box pt={2} display="flex" justifyContent="flex-start">
                                        <LoadingButton loading={loading} size="small" variant="contained" style={{ width: 150 }} onClick={() => { handleResetPassword() }}>
                                            Save
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </LayoutContainer>
            </PageLayout>
        </div>
    )
}

export default ResetPasswordPage