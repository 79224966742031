import {actionIds, BaseAction} from "../common";
import {ILoginData} from "../../types/IUserState";

const submitLoginAction: (data: ILoginData) =>
    BaseAction = (data) => ({
    type: actionIds.SUBMIT_LOGIN,
    payload: {
        ...data,
    },
});

export default submitLoginAction;