import React, {ChangeEvent, ReactNode} from 'react'
import {Grid, GridSize, InputAdornment, TextField, ListItemText, Typography} from '@mui/material';
import classNames from 'classnames';

interface Props {
    className?: string;
    id?: string;
    label?: string;
    labelPlacement?: string;
    placeholder?: string;
    multiline?: boolean;
    error?: string;
    type?: string;
    onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    endAdornment?: ReactNode;
    size?: GridSize;
}

const FormInput = (props: Props) => {
    let labelSize: GridSize =
        props.labelPlacement === 'top' ? 12 :
            props.labelPlacement === 'start' ? 4 : 4

    let textfieldSize: GridSize =
        props.labelPlacement === 'top' ? 12 :
            props.labelPlacement === 'start' ? 8 :
                props.size !== undefined ? props.size :
                    props.label === undefined ? 12 : 8

    return (
        <div className={classNames('form-input', props.className)}>
            <Grid container alignItems="flex-start">
                {/* Label */}
                {props.label && <Grid item xs={labelSize}>
                    <ListItemText primary={props.label || 'label'} />
                </Grid>}

                {/* Textfield */}
                <Grid item xs={textfieldSize}>
                    <TextField
                        id={props.id}
                        variant="outlined"
                        size="small"
                        fullWidth
                        type={props.type}
                        placeholder={props.placeholder}
                        value={props.value}
                        multiline={props.multiline}
                        minRows={4}
                        maxRows={4}
                        onChange={props.onChange}
                        error={props.error ? true : false}
                        onKeyDown={(evt) => props.type === "number" && ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()} // block +,-,e in input type Number
                        InputProps={{
                            endAdornment: props.endAdornment &&
                                <InputAdornment position="end">
                                    {props.endAdornment}
                                </InputAdornment>
                        }}
                    />
                    {props.error && <Typography variant="body2" color="secondary">{props.error}</Typography>}
                </Grid>
            </Grid>
        </div>
    )
}

export default FormInput