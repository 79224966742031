import React from 'react';
import UpdateStatus2 from '../organisms/Harvest/UpdateStatus2';
import PageLayout from '../organisms/PageLayout/PageLayout';

const UpdateStatusPage2 = (props: any) => {
    return (
        <div className="">
            <PageLayout>
                <UpdateStatus2 />
            </PageLayout>
        </div>
    )
}

export default UpdateStatusPage2