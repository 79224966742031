import React from 'react';
import {IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import PageLayout from "../PageLayout/PageLayout";
import {PageHeader} from "../PageHeader/PageHeader";
import LayoutContainer from "../../atoms/LayoutContainer/LayoutContainer";
import {ArrowRight} from "../../atoms/ArrowRight";
import routes from "../../../config/routes";

const ReportList = () => {
    const navigate = useNavigate()

    const handleReports = (item) => {
        if (item === 'Activity') {
            navigate(routes.REPORT_ACTIVITY)
        } else if (item === 'Overview') {
            navigate(routes.REPORT_OVERVIEW)
        } else if (item === 'Export') {
            navigate(routes.REPORT_EXPORT)
        }
    }

    return (
        <PageLayout>
            <PageHeader caption="Administration > Reports" />
            <LayoutContainer>
                {['Activity', 'Overview', 'Export'].map((item, index) => {
                    return (
                        <List key={index}>
                            <ListItem component="div">
                                <ListItem disableGutters component="div">
                                    <ListItemText primary={item} />
                                </ListItem>
                                <ListItem disableGutters component="div">
                                    {/*<ListItemText primary={`${item.zones?.length} Zones`} style={{ textAlign: 'right' }} />*/}
                                </ListItem>
                                <ListItemSecondaryAction onClick={() => {handleReports(item)}}>
                                    <IconButton edge="end" aria-label="edit">
                                        <ArrowRight color={'currentColor'} />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                })}
            </LayoutContainer>
        </PageLayout>
    )
}

export default ReportList