import React from 'react'
import './Notification.scss'
import INotification from "../../../types/INotification";
import {Alert} from '@mui/material';
import {useDispatch} from "react-redux";
import unsetNotification from "../../../state/actions/unsetNotification";

interface Props {
    notification: INotification;
}

const Notification = (props: Props) => {
    const dispatch = useDispatch()

    const closeNotification = (notification: INotification) => {
        dispatch(unsetNotification(notification))
    }

    return (
        <Alert variant="filled" className="notification" severity={props.notification.type} onClick={() => closeNotification(props.notification)}>
            {props.notification.message}
        </Alert>
    )
}

export default Notification