import React, {useEffect, useRef} from 'react';
import {Button, Grid, Paper} from "@mui/material";
import routes from '../../config/routes';
import QrReader from 'modern-react-qr-reader';
import {useNavigate} from "react-router-dom";
import {useDispatch} from 'react-redux';
import setHarvestDataAction from '../../state/actions/setHarvestData';
import PageLayout from '../organisms/PageLayout/PageLayout'
import {parseQrcode, ParseQrcodeResult} from "../../lib/parseQrcode";
import {PageHeader} from "../organisms/PageHeader/PageHeader";
import getSyncQueue from '../../state/actions/getSyncQueue';
import setGpsPosition from "../../state/actions/setGpsPosition";
import {saveErrorLog, saveScannedQrHistory} from "../../config/db";

interface Props {
    className?: string;
    onClick?: any;
}

function getLotName(scanData: ParseQrcodeResult) {
    if (scanData.row === '' || scanData.column === '') {
        return '';
    }
    return scanData.zoneName + '-R' + scanData.row + '-C' + scanData.column;
}

const ScanQrPage = (props: Props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getSyncQueue())
        navigator.geolocation.getCurrentPosition(position => {
            console.log('got gps data:', position.coords)
            // need to map data as GeolocationCoordinates cannot be JSON stringified directly
            dispatch(setGpsPosition({
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                heading: position.coords.heading,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                speed: position.coords.speed
            }))
        }, err => {
            console.log('could not get gps data:', err)
        }, {timeout: 60000})
    }, [dispatch])

    // Development only
    let dev = false;
    const qrRef = useRef<any>(null);
    //

    const handleErrorCamera = (error: any) => {
        console.log(error);
    }

    const handleScanCamera = (result: any) => {
        if (result) {
            const scanData = parseQrcode(result)
            if (scanData) {
                saveScannedQrHistory(parseInt(scanData.lotId), result)
                dispatch(setHarvestDataAction({
                    version: scanData.version,
                    lot_id: scanData.lotId,
                    zoneName: scanData.zoneName,
                    zoneId: scanData.zoneId,
                    crop_id: scanData.cropId,
                    cropsPerLot: scanData.cropsPerLot,
                    lot: {
                        id: scanData.lotId,
                        name: getLotName(scanData),
                        zone: scanData.zoneName,
                        row: scanData.row,
                        column: scanData.column,
                        status: ''
                    }
                }))
                if (parseInt(scanData.cropsPerLot) < 1) {
                    dispatch(setHarvestDataAction({
                        crop_index: 1,
                    }))
                    navigate(routes.UPDATE_STATUS_PAGE_2)
                } else {
                    navigate(routes.UPDATE_STATUS_PAGE)
                }
            } else {
                saveErrorLog('/scan-qr', 'failed to parse qr code', result)
                saveScannedQrHistory(0, result)
            }
        }
    }

    const onScanFile = () => {
        qrRef.current.openImageDialog();
    }

    return (
        <div className="">
            <PageLayout>
                {/* Lot Action Status */}
                <PageHeader caption="Scan QR Code"/>

                <Grid container justifyContent="center">
                    <Paper variant="outlined" style={{padding: 20, width: '100%', maxWidth: 640}}>
                        <QrReader
                            ref={qrRef}
                            delay={0}
                            style={{width: '100%'}}
                            onError={handleErrorCamera}
                            onScan={handleScanCamera}
                            legacyMode={dev} // file scanning only available in legacy mode, set false in prod
                            showViewFinder={false}
                        />
                    </Paper>
                </Grid>

                {/* Development only */}
                {dev && <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Button variant="contained" onClick={onScanFile}>
                        Scan QRcode from gallery
                        <input type="file" accept="image/*" hidden/>
                    </Button>
                </div>}
            </PageLayout>
        </div>
    )
}

export default ScanQrPage