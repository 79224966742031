import React, {ReactNode} from 'react';
import classNames from 'classnames';
import './LayoutContainer.scss';

interface Props {
    className?: string;
    children: ReactNode;
}

const LayoutContainer = (props: Props) => {
    return (
        <div className={classNames(
            'layout-container',
            props.className
        )}>{props.children}</div>
    )
}

export default LayoutContainer