import {combineReducers} from 'redux';
import {IRootState} from "../../types/IRootState";
import loadingReducer from "./loaderReducer";
import configReducer from "./configReducer";
import userReducer from './userReducer';
import harvestReducer from './harvestReducer';
import adminReducer from './adminReducer';
import warehouseReducer from "./warehouseReducer";
import cropsReducer from "./cropsReducer";
import syncReducer from "./syncReducer";
import notificationReducer from './notificationReducer';
import reportReducer from './reportReducer';

const rootReducer = combineReducers<IRootState>({
    config: configReducer,
    crops: cropsReducer,
    loader: loadingReducer,
    user: userReducer,
    notifications: notificationReducer,
    harvest: harvestReducer,
    admin: adminReducer,
    report: reportReducer,
    sync: syncReducer,
    warehouse: warehouseReducer
})

export default rootReducer