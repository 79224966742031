import {actionIds, BaseAction} from "../common";

interface Prop {
    // farm id
    selectedFarm: string
}

const setFarmSelected: (data: Prop) =>
    BaseAction = (data) => ({
    type: actionIds.SET_SELECTED_FARM,
    payload: {
        ...data
    },
});

export default setFarmSelected;
